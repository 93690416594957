import React from 'react'
import classnames from 'classnames'
import Heading from './Heading'
const BaseHeader = ({
  title,
  titleId,
  className = null,
  renderRight = null,
  renderLeft = null,
  type = 'section',
  renderContent = null,
}) => {
  return (
    <div className={classnames(['baseHeader', 'heading', `${type}Heading`, className])}>
      {renderContent ? (
        renderContent
      ) : (
        <>
          <div className="baseHeaderLeft">
            {renderLeft || <Heading title={title} titleId={titleId} />}
          </div>
          <div className="baseHeaderRight">{renderRight}</div>
        </>
      )}
    </div>
  )
}

export default React.memo(BaseHeader)
