import { fetchQwebApi } from '@/apis/qwebApi'
const event = 'manageSearchHistory'

export const getHistorys = () => {
  return fetchQwebApi(
    { event, data: { action: 'get' } },
    {
      initial: {
        list: [],
      },
    }
  )
}

export const addHistory = (word) => {
  return fetchQwebApi(
    { event, data: { action: 'add', data: word } },
    {
      initial: {
        list: [],
      },
    }
  )
}

export const removeHistory = (word) => {
  return fetchQwebApi(
    { event, data: { action: 'delete', data: word } },
    {
      initial: {
        list: [],
      },
    }
  )
}

export const clearHistory = () => {
  return fetchQwebApi(
    { event, data: { action: 'clear' } },
    {
      initial: {
        list: [],
      },
    }
  )
}
