import { qwebApi, fetchQwebApi } from '@/apis/qwebApi'

export const setData = (filename, data) => {
  try {
    qwebApi({
      event: 'fileStorageManager',
      data: {
        action: 'set',
        filename,
        data: JSON.stringify(data),
      },
    })
  } catch (error) {}
}

export const deleteData = (filename) => {
  try {
    qwebApi({
      event: 'fileStorageManager',
      data: {
        action: 'delete',
        filename,
      },
    })
  } catch (error) {}
}

export const getData = async (filename, defValue = []) => {
  try {
    const data = await fetchQwebApi({
      event: 'fileStorageManager',
      data: {
        action: 'get',
        filename,
      },
    })
    return JSON.parse(data.data)
  } catch (error) {
    return defValue
  }
}
window.deleteData=deleteData
