import React from 'react'
import classNames from 'classnames'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import './index.scss'
const SwiperSwitch = ({
  className,
  prevElClassName,
  nextElClassName,
  iconPrev = 'prev',
  iconNext = 'next',
}) => {
  return (
    <div className={classNames(['swiper-switch-container', className])}>
      <span className={classNames(['swiper-switch-icon', prevElClassName])}>
        <SvgIcon key="prev" size={1} icon={iconPrev} />
      </span>
      <span className={classNames(['swiper-switch-icon', nextElClassName])}>
        <SvgIcon key="next" size={1} icon={iconNext} />
      </span>
    </div>
  )
}

export default SwiperSwitch
