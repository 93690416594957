import { Redirect } from 'react-router-dom'

// import Home from '@/components/Home/Home'
// import CloudGame from '@/pages/cloudGame/Index'
// import NotFound from '@/pages/notFound/Index'
import Search from '@/pages/search/Index'
import GoogleSearch from '@/pages/googleSearch/Index'
import Apps from '@/pages/apps/Index'
import ByTopicApps from '@/pages/apps/ByTopicApps'
import Collections from '@/pages/collections/Index'
import GameCenter from '@/pages/gameCenter/Index'
import Home from '@/pages/home/Index'
import AppDetail from '@/pages/appDetail/Index'

const routes = [
  {
    path: '/gameCenter',
    exact: true,
    component: GameCenter,
  },
  {
    path: '/home',
    exact: true,
    component: Home,
  },
  {
    path: '/app/:packageName',
    exact: true,
    component: AppDetail,
  },
  // {
  //   path: '/cloudGame',
  //   exact: true,
  //   component: CloudGame,
  // },
  {
    path: '/search',
    exact: true,
    component: Search,
  },
  {
    path: '/googleSearch',
    exact: true,
    component: GoogleSearch,
  },
  {
    path: '/apps',
    exact: true,
    component: Apps,
  },
  {
    path: '/byTopicApps',
    exact: true,
    component: ByTopicApps,
  },
  {
    path: '/collections',
    exact: true,
    component: Collections,
  },
  {
    path: '*',
    render: () => {
      return (
        <Redirect
          to={{
            pathname: '/gameCenter',
          }}
        />
      )
    },
  },
]

export default routes
