import React, { useMemo, useImperativeHandle } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Lazy, Virtual, Navigation } from 'swiper'
import classNames from 'classnames'
import BaseHeader from '@/components/common/Heading/BaseHeader'
import SwiperSwitch from '@/components/common/SwiperSwitch/Index'
import { usePxToPx } from '@/hook/useViewport'
import './index.scss'

SwiperCore.use([Virtual, Navigation, Lazy])

const CarouselLayout = (
  {
    className,
    id,
    titleId,
    title,
    list,
    carouselKeyId = 'carousel',
    slideConfig = {},
    slidesPerView = 4,
    slidesPerGroup = slidesPerView,
    children,
    renderHeader,
    renderHeaderLeft,
    renderHeaderRight,
    keyId,
    gap = 24,
    lazyOptions = {
      loadPrevNext: true,
      loadPrevNextAmount: slidesPerView,
      elementClass: 'imageImg',
    },
  },
  ref
) => {
  const nextElClassName = `swiper-button-next_${carouselKeyId}`
  const prevElClassName = `swiper-button-prev_${carouselKeyId}`

  const gridGap = usePxToPx(gap)

  // swiper配置参数
  const settings = useMemo(() => {
    return {
      simulateTouch: false,
      spaceBetween: gridGap,
      navigation: {
        nextEl: `.${nextElClassName}`,
        prevEl: `.${prevElClassName}`,
        disabledClass: 'disabled',
      },
      slidesPerView,
      slidesPerGroup,
      lazy: lazyOptions,
      ...slideConfig,
    }
  }, [
    slidesPerView,
    prevElClassName,
    nextElClassName,
    slidesPerGroup,
    lazyOptions,
    slideConfig,
    gridGap,
  ])

  useImperativeHandle(ref, () => {
    return {}
  })

  if (!Array.isArray(list) || list.length === 0) {
    return null
  }
  return (
    <div className={classNames(['carousel-layout', className])} id={id}>
      {(renderHeader &&
        (typeof renderHeader === 'function'
          ? renderHeader({
            nextElClassName,
            prevElClassName,
            swiperSwitch: (
              <SwiperSwitch prevElClassName={prevElClassName} nextElClassName={nextElClassName} />
            ),
          })
          : renderHeader)) || (
          <BaseHeader
            titleId={titleId}
            title={title}
            renderLeft={renderHeaderLeft}
            renderRight={renderHeaderRight}
          />
        )}
      <Swiper {...settings}>
        {list.map((banner, index) => {
          return (
            <SwiperSlide virtualIndex={index} key={banner[keyId] || index}>
              {(slideOptions) => children(banner, index, slideOptions)}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default React.forwardRef(CarouselLayout)
