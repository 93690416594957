import React from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
// import Button from '../Button'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import googlePlayIcon from '@/images/google.svg'
import style from './index.module.scss'
// import SearchBgImg from '@/images/gp_search_bg.svg'

const GooglePlayButtonLight = ({ className, children, ...props }) => {
  return (
    <div className={classNames([style.container, className])} type="primary" {...props}>
      <SvgIcon alt="gp_search_bg" className={style.bgIcon} icon={'gp_search_bg'} />
      {children ? (
        children
      ) : (
        <div className={style.inner}>
          <img src={googlePlayIcon} alt="Search in google play" />
          <span><FormattedMessage id="openGooglePlayApp" /></span>
        </div>
      )}
    </div>
  )
}

export default GooglePlayButtonLight
