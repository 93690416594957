import React from 'react'

import FullScreenScroll from '@/components/FullScreenScroll/Index'

// import Banner from './Banner'
import List from './List'
import './index.scss'

const Home = () => {
  return (
    <FullScreenScroll
      scrollKey="home_v2"
      className="homeContainer"
      // renderHeader={({ isActive }) => {
      //   return <Banner isActive={isActive} className="breakScreen"/>
      // }}
      renderBody={({ isActive }) => {
        return <List isActive={isActive} />
      }}
    />
  )
}

export default React.memo(Home)
