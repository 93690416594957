// gameCenterRotationLoading
export const SET_GAME_CENTER_ROTATION_LOADING = 'SET_GAME_CENTER_ROTATION_LOADING'

// gameCenterHomeLoading
export const SET_GAME_CENTER_HOME_LOADING = 'SET_GAME_CENTER_HOME_LOADING'

// cloudGameLoading
export const SET_CLOUD_LOADING_LOADING = 'SET_CLOUD_LOADING_LOADING'

// cpiAppsForQuestLoading
export const SET_CPI_APPS_FOR_REQUEST_LOADING = 'SET_CPI_APPS_FOR_REQUEST_LOADING'

// appPageDetailsLoading
export const SET_APP_PAGE_DETAILS_LOADING = 'SET_APP_PAGE_DETAILS_LOADING'

// appLauncherSearchLoading
export const SET_LAUNCHER_SEARCH_LOADING = 'SET_LAUNCHER_SEARCH_LOADING'

// topicDatasLoading
export const SET_TOPIC_DATAS_LOADING = 'SET_TOPIC_DATAS_LOADING'

// suggestedAppsLoading
export const SET_SUGGESTED_APPS_LOADING = 'SET_SUGGESTED_APPS_LOADING'

// sidePanelLoading
export const SET_SIDE_PANEL_LOADING = 'SET_SIDE_PANEL_LOADING'
