import React, { useMemo, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useHover } from 'ahooks'
import AspectImage from '@/components/common/Image/AspectImage'
import MarqueeText from '@/components/common/marqueeText/Index'
import Button from '@/components/common/Button/Button'
import { STAT_GOOGLE_SEARCH_PAGE_SEARCH_BAR } from '@/tool/constant'
import { onGameClickAction } from '@/actions/commonActions'
import useAppLaunchParams from '@/hook/useAppLaunchParams'
import { getButtonText } from '@/tool/buttonText'
import style from './resultItem.module.scss'
import classNames from 'classnames'
const ResultItem = ({ app, as = 'div' }) => {
  const intl = useIntl()
  const containerRef = useRef(null)
  const installedApps = useSelector((state) => state.app.installedApps)
  const oneButtonConfig = useSelector((state) => state.user.clientUserConfig.one_button_config)
  const localPerference = useSelector((state) => state.app.localPerference[app.package_name])
  const isInstalled = installedApps.includes(app.package_name)

  /**
   * On click app event
   */
  const handleOnClick = useCallback(
    ({ evt, gameType, actionCode, redirectCode }) => {
      onGameClickAction({
        app: app,
        isInstalled,
        evt,
        gameType,
        bannerLocation: STAT_GOOGLE_SEARCH_PAGE_SEARCH_BAR,
        actionCode,
        redirectCode,
      })
    },
    [app, isInstalled]
  )

  const isHovering = useHover(containerRef)

  // App actions list
  const buttonsJSX = useMemo(() => {
    const buttonJSX = []
    if (oneButtonConfig === 3) {
      // User selector mode
      if (app.showCloudBtn) {
        const actionText = intl.formatMessage({ id: 'playInBrowser' })
        buttonJSX.push(
          <Button
            size="mini"
            key="playOnCloudButton"
            type="success"
            className={style.actionButton}
            onClick={(evt) =>
              handleOnClick({
                evt,
                gameType: 'cloud',
                actionCode: 'playOnCloud',
                redirectCode: 'AppLauncher',
              })
            }
          >
            {isHovering ? <MarqueeText>{actionText}</MarqueeText> : <font>{actionText}</font>}
          </Button>
        )
      }
      if (app.showPlayBtn) {
        const { actionText, actionCode } = getButtonText(intl, {
          isInstalled,
          app: app,
          platforms: 'local',
          oneButtonConfig,
        })
        buttonJSX.push(
          <Button
            size="mini"
            key="playOnBsButton"
            type="primary"
            className={style.actionButton}
            onClick={(evt) =>
              handleOnClick({ evt, gameType: 'simulator', actionCode, redirectCode: 'AppLauncher' })
            }
          >
            {isHovering ? <MarqueeText>{actionText}</MarqueeText> : <font>{actionText}</font>}
          </Button>
        )
      }
    } else {
      // One button mode and not a PC game
      if (!app.showPcBtn) {
        const { actionText, actionCode } = getButtonText(intl, {
          isInstalled,
          app: app,
          platforms: localPerference,
          oneButtonConfig: 3,
        })
        const isPlayOnCloud = actionCode === 'playOnCloud'
        const _actionText = isPlayOnCloud ? intl.formatMessage({ id: 'playInBrowser' }) : actionText
        buttonJSX.push(
          <Button
            size="mini"
            key="installButton"
            type={isPlayOnCloud ? 'success' : 'primary'}
            className={style.actionButton}
            onClick={(evt) =>
              handleOnClick({ evt, gameType: 'simulator', actionCode, redirectCode: 'AppLauncher' })
            }
          >
            {isHovering ? <MarqueeText>{_actionText}</MarqueeText> : <font>{_actionText}</font>}
          </Button>
        )
      }
    }

    // Show steam game button
    if (app.showPcBtn) {
      buttonJSX.push(
        <Button
          size="mini"
          key="checkPcGameButton"
          titleId="checkPcGame"
          type="success"
          className={style.actionButton}
          onClick={(evt) => handleOnClick({ evt, gameType: 'steam' })}
        />
      )
    }
    return buttonJSX
  }, [app, isHovering, handleOnClick, intl, isInstalled, localPerference, oneButtonConfig])

  // Click item event(direct to app detail)
  const { gameType, actionCode } = useAppLaunchParams(app)
  const onItemClick = useCallback(
    (evt) => {
      handleOnClick({ evt, gameType, actionCode, redirectCode: 'AppDetail' })
    },
    [gameType, actionCode, handleOnClick]
  )
  const AsDOm = as
  return (
    <AsDOm
      className={classNames([style.container, { pointer: !app.steam_supported }])}
      ref={containerRef}
      onClick={(evt) => !app.steam_supported && onItemClick(evt)}
    >
      <div className={style.left}>
        <AspectImage aspectClass="ratio-1-1" src={app.icon_url} />
      </div>
      <div className={style.right}>
        <div className={style.title}>{app.game_name}</div>
        <div className={style.actions}>{buttonsJSX}</div>
      </div>
    </AsDOm>
  )
}

export default ResultItem
