import React, { useEffect } from 'react'
import classNames from 'classnames'
import { BrowserRouter } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { useDispatch, useSelector } from 'react-redux'
import routes from '@/router'
import Setup from '@/components/setup/Index'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import { isQtClient } from '@/tool/qwebchannel/utils'
import { ViewportProvider } from '@/hook/useViewport'

import CommonAnimation from '@/components/common/Animation/CommonAnimation'

// actions
import { initGameCenterApps, initCloudGamesApps, fetchSuggestedApps } from '@/reducers/app/actions'
import { fetchCountryCode } from '@/reducers/system/actions'

const App = () => {
  const dispatch = useDispatch()
  const theme = useSelector((state) => state.user.theme)
  const countryCode = useSelector((state) => state.system.countryCode)
  const clientMode = useSelector((state) => state.system.mode)
  const localeCode = useSelector((state) => state.system.localeCode)
  const showOfflinePage = useSelector((state) => state.system.showOfflinePage)
  const fullscreenState = useSelector((state) => state.system.fullscreenState)

  // Fetch country code
  useEffect(() => {
    dispatch(fetchCountryCode())
  }, [dispatch])

  // Fetch jun's game apps
  useEffect(() => {
    if (countryCode === 'JP') {
      dispatch(initCloudGamesApps({}))
    }
  }, [dispatch, localeCode, countryCode, clientMode])

  // Fetch suggested apps
  useEffect(() => {
    if (countryCode) {
      dispatch(fetchSuggestedApps(countryCode))
    }
  }, [dispatch, countryCode])

  useEffect(() => {
    // Begin to fetch initialization data
    if (countryCode) {
      dispatch(initGameCenterApps())
    }
  }, [dispatch, countryCode, clientMode])

  // Prevent tab key function
  useEffect(() => {
    function onKeyDownHandler(evt) {
      if (evt.keyCode === 9) {
        if (evt.preventDefault) {
          evt.preventDefault()
        } else {
          evt.returnValue = false
        }
      }
    }
    window.addEventListener('keydown', onKeyDownHandler)
    return () => {
      window.removeEventListener('keydown', onKeyDownHandler)
    }
  }, [])

  return (
    <ViewportProvider>
      <div id="appRoot" className={theme}>
        <ErrorFallback>
          <div className={classNames(['container', { isQtClient, fullscreenState }])}>
            <BrowserRouter>
              <Setup />
              {showOfflinePage ? null : <CommonAnimation>{renderRoutes(routes)}</CommonAnimation>}
            </BrowserRouter>
          </div>
        </ErrorFallback>
      </div>
    </ViewportProvider>
  )
}

export default App
