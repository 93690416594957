import React, { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import GameGridLayout from '@/components/layout/gameGridLayout/Index'
import GameCarouselSection from '@/components/CarouselSection/GameCarouselSection'
import CollectionCarouselSection from '@/components/CarouselSection/CollectionCarouselSection'
import SectionCarousel from '@/components/AdvertisementSection/SectionCarousel'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import SkeletonLargeCarousel from '@/skeletons/LargeCarousel'
import SkeletonGameList from '@/skeletons/GameList'

import { shuffleRecommendedApps } from '@/reducers/app/actions'
import { useGridBaseCount } from '@/hook/useViewport'

// import ImaContainer from '@/components/Ima/ImaContainer'

import { arrayShuffle } from '@/tool'
import {
  STAT_GAMECENTER_PAGE_RECOMMENDED,
  STAT_GAMECENTER_PAGE_FEATURE,
  STAT_GAMECENTER_PAGE_MUSTPLAYGAMES,
  STAT_GAMECENTER_PAGE_NEWANDTRENDING,
} from '@/tool/constant'

const List = ({ isActive }) => {
  const dispatch = useDispatch()
  const countryCode = useSelector((state) => state.system.countryCode)
  const loadingStore = useSelector((state) => state.loading)

  // 置顶游戏（selected_game）
  const topApps = useSelector((state) => state.app.topApps.slice(0, 20))

  // 火热游戏（hot_list）
  const hotApps = useSelector((state) => state.app.hotApps.slice(0, 26))

  // Feature app
  const featureApps = useSelector((state) => state.app.featureApps)

  // 最新游戏（latest_game）
  const recommendedApps = useSelector((state) => state.app.recommendedApps)

  // collection
  const topics = useSelector((state) => state.app.topics)

  const gridCount = useGridBaseCount()

  // refresh recommended for you apps
  const onRefresh = useCallback(() => {
    dispatch(shuffleRecommendedApps(arrayShuffle([...recommendedApps])))
  }, [recommendedApps, dispatch])

  // strategy apps show
  const recommendedAppsForShow = useMemo(() => {
    if (countryCode === 'JP') {
      return recommendedApps.slice(0, 12)
    }
    const topApps = [...recommendedApps].filter((app, o) => {
      return !!app.banner_url_v
    })
    const lastApps = [...recommendedApps].filter((app, o) => {
      return !app.banner_url_v
    })
    return topApps.concat(lastApps).slice(0, 12)
  }, [recommendedApps, countryCode])
  return (
    <div className="gameCenterList breakScreen">
      {/* Recommended For You */}
      {recommendedAppsForShow.length ? (
        <ErrorFallback>
          <GameCarouselSection
            className={{ hideHeader: !isActive }}
            carouselKeyId="recommendedGames"
            titleId="recommendedGames"
            bannerLocation={STAT_GAMECENTER_PAGE_RECOMMENDED}
            list={recommendedAppsForShow}
            linkTo={{
              pathname: 'apps',
              search: 'type=recommendedApps&from=gameCenter&titleId=recommendedGames',
            }}
            onRefresh={onRefresh}
            pagination={false}
            showPlayBtn
            showCloudBtn
            imgPlaceholderClass="ratio-3-4"
            imageUrlGet={(game) => (countryCode === 'JP' ? game.icon_url : game.banner_url_v)}
            showInstalledState
            slidesPerView={gridCount - 1}
            isGroup={false}
          />
        </ErrorFallback>
      ) : (
        <SkeletonGameList
          imgPlaceholderClass="ratio-3-4"
          titleId="recommendedGames"
          column={gridCount - 1}
          enable={loadingStore.gameCenterHomeLoading}
        />
      )}
      {/* featureApps */}
      {featureApps.length ? (
        <ErrorFallback>
          <SectionCarousel
            list={featureApps}
            bannerLocation={STAT_GAMECENTER_PAGE_FEATURE}
            showInstalledState
            className="margin-top-60 margin-bottom-30"
          />
        </ErrorFallback>
      ) : (
        <SkeletonLargeCarousel
          actionCount={1}
          titleId="featuredGame"
          imgPlaceholderClass="ratio-131-50"
          enable={loadingStore.cpiAppsForQuestLoading}
        />
      )}

      {/* browser by collection */}
      <ErrorFallback>
        <CollectionCarouselSection
          titleId="browseByCollection"
          className="margin-bottom-30"
          isLoading={loadingStore.gameCenterHomeLoading}
          columns={gridCount}
          list={topics}
        />
      </ErrorFallback>
      {/* Must-Play Games */}
      {topApps.length ? (
        <ErrorFallback>
          <GameCarouselSection
            titleId="topGames"
            list={topApps}
            carouselKeyId="topGames"
            bannerLocation={STAT_GAMECENTER_PAGE_MUSTPLAYGAMES}
            linkTo={{
              pathname: 'apps',
              search: 'type=topApps&from=gameCenter&titleId=topGames',
            }}
            imgPlaceholderClass="ratio-1-1"
            showPlayBtn
            showCloudBtn
            showInstalledState
            pagination={false}
            slidesPerView={1}
            groupNumber={gridCount * 2}
            renderItem={({ slide, itemProps }) => {
              return <GameGridLayout gameItemProps={itemProps} list={slide} columns={gridCount} />
            }}
          />
        </ErrorFallback>
      ) : (
        <SkeletonGameList
          imgPlaceholderClass="ratio-1-1"
          titleId="topGames"
          column={6}
          row={2}
          enable={loadingStore.gameCenterHomeLoading}
        />
      )}
      {/* New and Trending */}
      {hotApps.length ? (
        <ErrorFallback>
          <GameCarouselSection
            titleId="popularGames"
            carouselKeyId="popularGames"
            bannerLocation={STAT_GAMECENTER_PAGE_NEWANDTRENDING}
            list={hotApps}
            className="gameList"
            linkTo={{
              pathname: 'apps',
              search: 'type=hotApps&from=gameCenter&titleId=popularGames',
            }}
            pagination={false}
            imgPlaceholderClass="ratio-1-1"
            showPlayBtn
            showCloudBtn
            showInstalledState
            slidesPerView={1}
            groupNumber={gridCount * 3}
            renderItem={({ slide, itemProps }) => {
              return (
                <GameGridLayout
                  contentInnerClass="margin-bottom-30"
                  gameItemProps={itemProps}
                  list={slide}
                  columns={gridCount}
                />
              )
            }}
          />
        </ErrorFallback>
      ) : (
        <SkeletonGameList
          imgPlaceholderClass="ratio-1-1"
          titleId="popularGames"
          column={gridCount}
          row={3}
          enable={loadingStore.gameCenterHomeLoading}
        />
      )}
    </div>
  )
}

export default React.memo(List)
