import React from 'react'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import Item from './Item'
import style from './index.module.scss'

const RecentSearch = ({ className }) => {
  const intl = useIntl()
  const apps = useSelector((state) => state.app.suggestedApps)
  if (apps.length === 0) {
    return null
  }
  return (
    <div className={classNames([style.container, className])}>
      <div className={style.header}>{intl.formatMessage({ id: 'recentSearch' })}</div>
      <div className={style.content}>
        <div className={style.contentWrap}>
          {apps.slice(0, 5).map((app) => {
            return <Item app={app} key={app.package_name} />
          })}
        </div>
      </div>
    </div>
  )
}

export default RecentSearch
