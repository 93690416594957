/**
 * List all topics for browse by collection
 */

import React from 'react'
import GridLayout from '@/components/layout/gridLayout/Index'
import CollectionItem from '@/components/common/Collection/Item'
import bgColorList from '@/components/common/Collection/bgColor'
const CollectionList = ({ className, columns = 5, list = [], customTitleRender }) => {
  return (
    <GridLayout
      className={className}
      customTitleRender={customTitleRender}
      columns={columns}
      list={list}
      keyId="topic_name"
      numberOfItems={list.length}
    >
      {(item, index) => {
        return <CollectionItem styleObj={bgColorList[index % bgColorList.length]} topic={item} />
      }}
    </GridLayout>
  )
}

export default React.memo(CollectionList)
