import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { findDOMNode } from 'react-dom'
import classnames from 'classnames'
import { Player, ControlBar, BigPlayButton } from 'video-react'
import './index.scss'
import style from './index.module.scss'
const Image = ({
  className,
  src,
  onClick,
  alt,
  onLoad,
  onError,
  // 图片是否lazy-load-
  styleObj,
  lazy = false,
  // -----lazy为true有效---------------------------
  // 不采用内置方式lazy-load，适配swiper
  selfLazyLoad = false,
  // 单距离多少进行加载，类似margin语法
  rootMargin = '500px',
  // offset相对容器（最好设置成图片所在滚动容器）
  root = null,
  // ----------------------------------------------
  isVideo,
  isEmbedSrc = false,
  children,
  objectFit = 'contain',
  position = 'center',
}) => {
  const domRef = useRef(null)
  const containerRef = useRef(null)
  const [isError, setIsError] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [containerClass, setContainerClass] = useState([])

  // 加载失败回调
  const _onLoadError = useCallback(
    (e) => {
      setIsLoaded(false)
      setIsError(true)
      if (onError) onError(e)
    },
    [onError]
  )

  // 加载成功回调
  const _onLoadSuccess = useCallback(
    (e) => {
      const container = containerRef.current
      const { naturalHeight, naturalWidth } = e.target
      let containerWidth = 0
      let containerHeight = 0
      if (container) {
        containerWidth = container.clientWidth
        containerHeight = container.clientHeight
      }
      let containerClassTemp = []
      // 图片大小小于容器大小时
      if (naturalHeight < containerHeight && naturalWidth < containerWidth) {
        containerClassTemp.push('includeSize')
      }
      if (naturalWidth / naturalHeight !== containerWidth / containerHeight) {
        containerClassTemp.push('ratioDifferent')
      }
      // 图片
      if (naturalHeight > naturalWidth) {
        containerClassTemp.push('heightGtWidth')
      } else if (naturalWidth > naturalHeight) {
        containerClassTemp.push('widthGtHeight')
      } else {
        containerClassTemp.push('squareSize')
      }
      // 容器
      if (containerHeight > containerWidth) {
        containerClassTemp.push('containerHeightGtWidth')
      } else if (containerWidth > containerHeight) {
        containerClassTemp.push('containerWidthGtHeight')
      } else {
        containerClassTemp.push('containerSquareSize')
      }
      setContainerClass(containerClassTemp)
      setIsLoaded(true)
      setIsError(false)
      if (onLoad) {
        onLoad(e)
      }
    },
    [onLoad]
  )
  // 是否显示默认placeholder
  const showPlaceholder = !src || !isLoaded || isError
  const srcProps = useMemo(() => {
    if (isVideo) {
      return {
        src,
      }
    }
    if (lazy) {
      return { 'data-src': src }
    } else {
      return { src }
    }
  }, [lazy, src, isVideo])
  // 懒加载
  useEffect(() => {
    let observer
    if (lazy && !selfLazyLoad && domRef.current) {
      function callback(entries) {
        for (let entry of entries) {
          if (entry.intersectionRatio > 0 || entry.isIntersecting) {
            const srcUrl = entry.target.dataset['src']
            if (srcUrl) {
              entry.target.src = srcUrl
            }
            observer.unobserve(entry.target)
          }
        }
      }
      observer = new IntersectionObserver(callback, {
        root:
          typeof root === 'function' ? root() : root?.current ? findDOMNode(root.current) : root,
        rootMargin: typeof rootMargin === 'function' ? rootMargin() : rootMargin,
      })
      observer.observe(domRef.current)
    }
    return () => {
      observer && observer.disconnect()
    }
  }, [lazy, selfLazyLoad, src, rootMargin, root])
  return (
    <div
      ref={containerRef}
      onClick={onClick}
      className={classnames([
        'imageContainer',
        position,
        { defaultImge: showPlaceholder },
        containerClass,
        className,
      ])}
    >
      {isVideo ? (
        <>
          {isEmbedSrc ? (
            <iframe
              {...srcProps}
              title={src}
              className={style.embedSrcContainer}
              allowFullScreen
              frameBorder="0"
            />
          ) : (
            <Player {...srcProps}>
              <BigPlayButton position="center" />
              <ControlBar autoHideTime={1000} disableDefaultControls={true}></ControlBar>
            </Player>
          )}
        </>
      ) : (
        <img
          ref={domRef}
          className="imageImg imageContainerChild"
          alt={alt || src}
          onError={(e) => _onLoadError(e)}
          onLoad={(e) => _onLoadSuccess(e)}
          {...srcProps}
          style={{ objectFit, ...styleObj }}
        />
      )}
      {children}
    </div>
  )
}

export default React.memo(Image)
