import * as types from './constant'

export const setGameCenterRotationLoading = (loading = true) => {
  return {
    type: types.SET_GAME_CENTER_ROTATION_LOADING,
    payload: loading,
  }
}

export const setGameCenterHomeLoading = (loading = true) => {
  return {
    type: types.SET_GAME_CENTER_HOME_LOADING,
    payload: loading,
  }
}

export const setCpiAppsForQuestLoading = (loading = true) => {
  return {
    type: types.SET_CPI_APPS_FOR_REQUEST_LOADING,
    payload: loading,
  }
}

export const setCloudGameLoading = (loading = true) => {
  return {
    type: types.SET_CLOUD_LOADING_LOADING,
    payload: loading,
  }
}

export const setAppPageDetailsLoading = (loading = true) => {
  return {
    type: types.SET_APP_PAGE_DETAILS_LOADING,
    payload: loading,
  }
}

export const setAppLauncherSearchLoading = (loading = true) => {
  return {
    type: types.SET_LAUNCHER_SEARCH_LOADING,
    payload: loading,
  }
}

export const setTopicDatasLoading = (loading = true) => {
  return {
    type: types.SET_TOPIC_DATAS_LOADING,
    payload: loading,
  }
}

export const setSuggestedAppsLoading = (loading = true) => {
  return {
    type: types.SET_SUGGESTED_APPS_LOADING,
    payload: loading,
  }
}

export const setSidePanelLoading = (loading = true) => {
  return {
    type: types.SET_SIDE_PANEL_LOADING,
    payload: loading,
  }
}
