import React, { useMemo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Lazy } from 'swiper'
import classNames from 'classnames'
import Heading from '@/components/common/Heading/Heading'
import SwiperSwitch from '@/components/common/SwiperSwitch/Index'
import Advertisement from './Advertisement'
import './sectionCarousel.scss'
SwiperCore.use([Navigation, Lazy])
const SectionCarousel = ({
  className,
  list,
  carouselKeyId = 'carousel',
  slideConfig = {},
  slidesPerView = 1,
  slidesPerGroup,
  bannerLocation,
}) => {
  const nextElClassName = `swiper-button-next_section_${carouselKeyId}`
  const prevElClassName = `swiper-button-prev_section_${carouselKeyId}`
  const settings = useMemo(() => {
    return {
      simulateTouch: false,
      spaceBetween: 20,
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 1,
        elementClass: 'imageImg',
      },
      navigation: {
        nextEl: `.${nextElClassName}`,
        prevEl: `.${prevElClassName}`,
        disabledClass: 'disabled',
      },
      slidesPerView: slidesPerView,
      slidesPerGroup: slidesPerGroup || slidesPerView,
      observer: true,
      observeParents: true,
      ...slideConfig,
    }
  }, [slidesPerView, prevElClassName, nextElClassName, slidesPerGroup, slideConfig])

  if (list.length === 0) {
    return null
  }
  return (
    <div className={classNames(['section-carousel-container', className])}>
      <div className="section-carousel-header">
        <Heading
          className={classNames(['heading', 'sectionHeading', 'section-carousel-headerTitle'])}
          titleId="featuredGame"
        />
        {list.length > slidesPerView ? (
          <SwiperSwitch prevElClassName={prevElClassName} nextElClassName={nextElClassName} />
        ) : null}
      </div>
      <Swiper {...settings} className="section-carousel-body">
        {list.map((banner, index) => {
          return (
            <SwiperSlide virtualIndex={index} key={index}>
              <Advertisement
                app={banner}
                // imgPlaceholderClass="ratio-131-50"
                showInstalledState
                bannerLocation={bannerLocation}
                className="section-carousel-item"
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default React.memo(SectionCarousel)
