import produce from 'immer'
import * as types from './constant'
// Theme map
const themeMap = {
  lighter: 'lighter',
}
// Theme storage key
export const THEME_STORAGE_KEY = 'THEME_STORAGE_KEY'

const INIT_STATE = {
  userInfo: {},
  theme: themeMap[window.localStorage.getItem(THEME_STORAGE_KEY)] || 'lighter',
  clientUserConfig: {
    // App action config,default:3 (user select)
    one_button_config: 3,
  },
}
const userReducer = produce((state, action) => {
  const { type, payload } = action
  switch (type) {
    case types.SET_USER_INFO: {
      state.userInfo = payload
      break
    }
    case types.SET_THEME: {
      if (themeMap[payload]) {
        window.localStorage.setItem(THEME_STORAGE_KEY, payload)
        state.theme = themeMap[payload]
      }
      break
    }
    case types.CLIENT_USER_CONFIG: {
      state.clientUserConfig = payload
      break
    }
    default: {
    }
  }
}, INIT_STATE)

export default userReducer
