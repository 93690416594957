import { useEffect, useCallback } from 'react'
import { on, off } from '@/apis/qwebApi'
const Hook = (onOpenDetail) => {
  // On update
  const onOpenDetailPage = useCallback(
    (app) => {
      onOpenDetail && onOpenDetail(app)
    },
    [onOpenDetail]
  )
  useEffect(() => {
    on('openDetailPage', onOpenDetailPage)
    return () => {
      off('openDetailPage', onOpenDetailPage)
    }
  }, [onOpenDetailPage])
}

export default Hook
