import { useState, useEffect, useCallback } from 'react'
import { on, off, qwebApi } from '@/apis/qwebApi'

const Hook = () => {
  const [state, setFullscreenState] = useState(false)
  useEffect(() => {
    qwebApi({ event: 'getFullscreenState', callbackEvent: 'updateFullscreenState' })
  }, [])
  // On fullscreen state change
  const onUpdateFullscreenState = useCallback((jsonObject) => {
    try {
      if (jsonObject && jsonObject.value) {
        setFullscreenState(jsonObject.value === 'true')
      } else {
        setFullscreenState(false)
      }
    } catch (error) {
      setFullscreenState(false)
    }
  }, [])
  // Register event listener client fullscreen state change
  useEffect(() => {
    on('updateFullscreenState', onUpdateFullscreenState)
    return () => {
      off('updateFullscreenState', onUpdateFullscreenState)
    }
  }, [onUpdateFullscreenState])
  return state
}

export default Hook
