const colors = [
  {
    background: 'linear-gradient(135deg, #E82745 0%, #2A91C1 100%)',
  },
  {
    background: 'linear-gradient(135deg, #C0D61A 0%, #27B093 100%)',
  },
  {
    background: 'linear-gradient(135deg, #4BABD9 0%, #58C878 100%)',
  },
  {
    background: 'linear-gradient(135deg, #17CADA 0%, #982FE8 100%)',
  },
  {
    background: 'linear-gradient(135deg, #F62008 0%, #E2A502 100%)',
  },
  {
    background: 'linear-gradient(135deg, #1C98CA 0%, #E82745 100%)',
  },
]

export default colors
