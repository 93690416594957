import React, { useMemo, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import BaseHeader from './BaseHeader'
import Heading from './Heading'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import classNames from 'classnames'
const GamePageHeader = ({ title, titleId, count, onBack, className }) => {
  const history = useHistory()
  const _onBack = useCallback(() => {
    if (onBack) {
      onBack()
    } else {
      history.goBack()
    }
  }, [onBack, history])

  const renderLeft = useMemo(() => {
    return (
      <div className="gamePageHeaderLeftWrap" onClick={_onBack}>
        <SvgIcon icon="arrow-left" className={['pointer', 'margin-right-10']} />
        <Heading title={title} titleId={titleId} />
      </div>
    )
  }, [_onBack, title, titleId])

  const renderRight = useMemo(() => {
    if (count > 0) {
      return (
        <FormattedMessage
          id={count === 1 ? 'subHeadingGameCountForSingle' : 'subHeadingGameCount'}
          values={{ count }}
        />
      )
    }
  }, [count])

  return (
    <BaseHeader
      className={classNames(['gamePageHeader', className])}
      renderRight={renderRight}
      renderLeft={renderLeft}
      type="page"
    />
  )
}

export default GamePageHeader
