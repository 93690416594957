import { useState, useEffect, useCallback } from 'react'
import { on, off, qwebApi } from '@/apis/qwebApi'

const Hook = () => {
  const [userConfig, setUserConfig] = useState({ one_button_config: 3 })
  useEffect(() => {
    qwebApi({ event: 'getUserConfig', callbackEvent: 'updateUserConfig' })
  }, [])
  // On update
  const onUpdateUserConfig = useCallback((jsonObject) => {
    try {
      if (jsonObject) {
        setUserConfig(jsonObject)
      }
    } catch (error) {
      setUserConfig({})
    }
  }, [])
  // Register event
  useEffect(() => {
    on('updateUserConfig', onUpdateUserConfig)
    return () => {
      off('updateUserConfig', onUpdateUserConfig)
    }
  }, [onUpdateUserConfig])
  return userConfig
}

export default Hook
