import React, { useMemo, useCallback, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { useHover } from 'ahooks'
import { useIntl } from 'react-intl'
import { useSwiper, useSwiperSlide } from 'swiper/react'

import AspectImage from '../Image/AspectImage'
import { onGameClickAction } from '@/actions/commonActions'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import Button from '@/components/common/Button/Button'
import MarqueeText from '@/components/common/marqueeText/Index'

import { getButtonText } from '@/tool/buttonText'
import { GOOGLE_PLAY_PCK } from '@/tool/constant'

import style from './index.module.scss'

const GameListItem = ({
  className,
  nameClass,
  categoryClass,
  onImageLoad,
  onImageError,
  showSubTitle = true,
  showInstalledState,
  showPlayBtn = true,
  showCloudBtn,
  showPcBtn,
  showTypeIcon,
  showDetailIcon = true,
  imgPlaceholderClass,
  isVideo,
  game,
  onClick,
  onHoverEnter,
  onRootClick = () => {},
  bannerLocation,
  imageUrlGet = false,
  hoverImageUrlGet = false,
  lazyRoot = null,
  lazyRootMargin,
  bootGameOnClick = true,
  selfLazyLoad,
  hasHoverAction = false,
  mouseEnterDelay = 200,
  lazy = true,
}) => {
  const intl = useIntl()
  const hoverRef = useRef(null)
  const hoverTimerRef = useRef(null)
  const installedApps = useSelector((state) => state.app.installedApps)
  const countryCode = useSelector((state) => state.system.countryCode)
  const oneButtonConfig = useSelector((state) => state.user.clientUserConfig.one_button_config)
  const localPerference = useSelector((state) => state.app.localPerference[game.package_name])
  const isInstalled = installedApps.includes(game.package_name)
  const swiper = useSwiper()
  const swiperSlide = useSwiperSlide()

  const isHovering = useHover(hoverRef, {
    onEnter: () => {
      if (hasHoverAction && onHoverEnter) {
        hoverTimerRef.current = setTimeout(
          () => onHoverEnter(game, swiper, swiperSlide),
          mouseEnterDelay
        )
      }
    },
    onLeave: () => {
      if (hasHoverAction) {
        clearTimeout(hoverTimerRef.current)
      }
    },
  })

  useEffect(() => {
    return () => {
      clearTimeout(hoverTimerRef.current)
    }
  }, [])

  // 点击项目
  const handleOnClick = useCallback(
    ({ evt, gameType, actionCode, redirectCode }) => {
      if (onClick) {
        onClick(game, evt)
      }
      if (bootGameOnClick) {
        onGameClickAction({
          app: game,
          isInstalled,
          evt,
          gameType,
          bannerLocation,
          actionCode,
          redirectCode,
        })
      }
    },
    [game, isInstalled, bannerLocation, onClick, bootGameOnClick]
  )

  // 获取图片url值
  const imageUrl = useMemo(() => {
    return (
      (imageUrlGet && imageUrlGet(game)) ||
      game.icon_url ||
      game.banner_url ||
      game.banner_url_v ||
      game.game_tile_url ||
      game.img_url
    )
  }, [game, imageUrlGet])

  // 获取hover图片url值
  const hoverImageUrl = useMemo(() => {
    return (hoverImageUrlGet && hoverImageUrlGet(game)) || imageUrl
  }, [game, hoverImageUrlGet, imageUrl])

  // 是否显示游戏按钮
  const hasItemBtn = showPlayBtn || showCloudBtn || showPcBtn

  // 是否显示查看详情按钮
  const hasDetailAction = useMemo(() => {
    if (!showDetailIcon || !game.isAppDetailAccessable || countryCode === 'JP') {
      return false
    }
    return true
  }, [game, countryCode, showDetailIcon])

  // 图片策略展示
  const imageJSX = useMemo(() => {
    const realSrc = isVideo
      ? game.video_url
      : hasHoverAction && isHovering
      ? hoverImageUrl
      : imageUrl

    const buttonJSX = []
    if (oneButtonConfig === 3) {
      // 日本区域按照双按钮逻辑处理
      if (countryCode === 'JP') {
        if (showCloudBtn && game.showCloudBtn) {
          const { actionText, actionCode, redirectCode, buttonType } = getButtonText(intl, {
            app: game,
            platforms: 'cloud',
            oneButtonConfig,
          })
          buttonJSX.push(
            <Button
              key="playOnCloudButton"
              className={classNames([style.itemImgButton])}
              type={buttonType}
              onClick={(evt) => handleOnClick({ evt, gameType: 'cloud', actionCode, redirectCode })}
            >
              {isHovering ? <MarqueeText>{actionText}</MarqueeText> : actionText}
            </Button>
          )
        }
        if (showPlayBtn && game.showPlayBtn) {
          const { actionText, actionCode, redirectCode, buttonType } = getButtonText(intl, {
            isInstalled,
            app: game,
            platforms: 'local',
            oneButtonConfig,
          })
          buttonJSX.push(
            <Button
              key="playOnBsButton"
              className={classNames([style.itemImgButton])}
              type={buttonType}
              onClick={(evt) =>
                handleOnClick({ evt, gameType: 'simulator', actionCode, redirectCode })
              }
            >
              {isHovering ? <MarqueeText>{actionText}</MarqueeText> : actionText}
            </Button>
          )
        }
      } else {
        if (!game.showPcBtn) {
          const { actionText, actionCode, redirectCode, buttonType } = getButtonText(intl, {
            isInstalled,
            app: game,
            platforms: localPerference,
            oneButtonConfig: 0,
          })
          buttonJSX.push(
            <Button
              key="installButton"
              className={classNames([style.itemImgButton])}
              type={buttonType}
              onClick={(evt) =>
                handleOnClick({ evt, gameType: 'simulator', actionCode, redirectCode })
              }
            >
              {isHovering ? <MarqueeText>{actionText}</MarqueeText> : actionText}
            </Button>
          )
        }
      }
    } else {
      if (!game.showPcBtn) {
        const { actionText, actionCode, redirectCode, buttonType } = getButtonText(intl, {
          isInstalled,
          app: game,
          platforms: localPerference,
          oneButtonConfig: 0,
        })
        buttonJSX.push(
          <Button
            key="installButton"
            className={classNames([style.itemImgButton])}
            type={buttonType}
            onClick={(evt) =>
              handleOnClick({ evt, gameType: 'simulator', actionCode, redirectCode })
            }
          >
            {isHovering ? <MarqueeText>{actionText}</MarqueeText> : actionText}
          </Button>
        )
      }
    }

    if (showPcBtn && game.showPcBtn) {
      buttonJSX.push(
        <Button
          key="checkPcGameButton"
          titleId="checkPcGame"
          className={classNames([style.itemImgButton])}
          type="primaryChange"
          onClick={(evt) => handleOnClick({ evt, gameType: 'steam' })}
        />
      )
    }
    let detailClickJSX = null
    if (hasDetailAction) {
      const { gameType, actionCode, redirectCode } = getButtonText(intl, {
        isInstalled,
        app: game,
        platforms: localPerference,
        oneButtonConfig,
      })
      let _actionCode = actionCode
      let _gameType = gameType
      // Support cloud game and the configuration is based on user selection
      if (game.cloud_supported && oneButtonConfig !== 0) {
        _actionCode = ''
        _gameType = ''
      }
      const isGooglePlay = game.package_name === GOOGLE_PLAY_PCK
      detailClickJSX = (
        <div
          className={style.detailClickPanel}
          onClick={(evt) =>
            handleOnClick({
              evt,
              gameType: _gameType,
              actionCode: _actionCode,
              redirectCode: isGooglePlay ? redirectCode : 'AppDetail',
            })
          }
        >
          <SvgIcon size={1.5} icon="zoom-in" className={style.detailIcon} />
        </div>
      )
    }
    return (
      <div
        className={classNames([style.slideUp, style.overlayContainer, 'gameItemOverlayContainer'])}
        ref={hoverRef}
      >
        {/* 图片自适应布局 */}
        <AspectImage
          aspectClass={
            typeof imgPlaceholderClass === 'function'
              ? imgPlaceholderClass(game)
              : imgPlaceholderClass
          }
          isVideo={isVideo}
          src={realSrc}
          selfLazyLoad={selfLazyLoad}
          lazy={lazy}
          root={lazyRoot}
          rootMargin={lazyRootMargin}
          videoUrl={game.video_url}
          onLoad={onImageLoad}
          onError={onImageError}
          alt={realSrc}
          className={style.transitionImage}
        >
          {/* 已安装 */}
          {showInstalledState && isInstalled && (
            <SvgIcon size={1} icon="installed" className="installed" />
          )}
          {/* 操作按钮-打开游戏或云 */}
          {(hasItemBtn || detailClickJSX) && (
            <div className={style.buttonOverlay}>
              <div className={style.buttonWrap}>
                {detailClickJSX}
                {buttonJSX}
              </div>
            </div>
          )}
        </AspectImage>
      </div>
    )
  }, [
    showCloudBtn,
    showPlayBtn,
    showPcBtn,
    handleOnClick,
    showInstalledState,
    isInstalled,
    game,
    hasItemBtn,
    imgPlaceholderClass,
    selfLazyLoad,
    onImageLoad,
    onImageError,
    imageUrl,
    isVideo,
    lazyRoot,
    lazyRootMargin,
    oneButtonConfig,
    hasHoverAction,
    hoverImageUrl,
    isHovering,
    lazy,
    intl,
    hasDetailAction,
    localPerference,
    countryCode,
  ])
  return (
    <div
      className={classNames([style.container, className])}
      onClick={(evt) => onRootClick(game, evt)}
    >
      {imageJSX}
      {game.game_name && (
        <p className={classNames([nameClass, style.gameName])}>{game.game_name}</p>
      )}
      {showSubTitle && (game.category || showTypeIcon) && (
        <div className={style.footerPanel}>
          <span className={classNames([categoryClass, style.category])}>{game.category}</span>
          {showTypeIcon && (
            <div className={style.gameType}>
              {game.showCloudBtn && (
                <div className={style.typeIcon}>
                  <SvgIcon size={1.5} icon="cloud" className={['c-white']} />
                </div>
              )}
              {game.showPcBtn && (
                <div className={style.typeIcon}>
                  <SvgIcon size={1.5} icon="pc" className={['c-white']} />
                </div>
              )}
              {game.showPlayBtn && (
                <div className={style.typeIcon}>
                  <SvgIcon size={1.5} icon="android" className={['c-white']} />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default GameListItem
