import { qwebApi } from '@/apis/qwebApi'
import Utils from '@/Utils'
import * as BannerLocation from '@/tool/constant'
// import { getTrackId } from '@/tool/track'

// Stat event type
export const STATS_POINT = {
  BSX_JUMP_TO_DETAIL_PAGE: 'App_JumpToDetailPage',
  BSX_SEARCH_IN_GP_CLICKED: 'SearchInGP_Clicked',
  BSX_GPSTORE_CLICKED: 'GPStore_Clicked',
  BSX_GPPAGE_VIEW_ALL_GAMES: 'ViewAllGames_Clicked',
}

// Stat table
export const TABLE_TYPE = {
  LAUNCHER_APP_CLICK: 'launcher_app_click_stats',
  LAUNCHER_APP_CLICK_V2: 'launcher_app_click_statsV2',
}

// view all key word
const VIEW_ALL = 'view all'

/**
 * @deprecated
 * Get stat info based on bannerLocation
 * @param {string} bannerLocation Page location
 * @returns StatInfo
 */
export const getLocationMapStatInfo = (bannerLocation) => {
  const statInfo = {
    banner_location: '',
    section: '',
  }
  switch (bannerLocation) {
    /**
     * Search page
     */
    // Search results for 'xxx'
    case BannerLocation.STAT_SEARCH_PAGE_RESULT:
      statInfo.banner_location = 'search_results_page'
      statInfo.section = 'search_results'
      break
    /**
     * App page
     */
    // App page from Home / Trending Games
    case BannerLocation.STAT_APPS_PAGE_TYPEFOR_TRENDINGGAMES_FROM_HOME:
      statInfo.banner_location = 'home'
      statInfo.section = 'trending_games'
      statInfo.arg1 = VIEW_ALL
      break
    // App page from Home / Popular Games in your region
    case BannerLocation.STAT_APPS_PAGE_TYPEFOR_POPULARGAMES_FROM_HOME:
      statInfo.banner_location = 'home'
      statInfo.section = 'popular_games'
      statInfo.arg1 = VIEW_ALL
      break
    // App page from Home / Recommended For You (deprecated)
    case BannerLocation.STAT_APPS_PAGE_TYPEFOR_RECOMMENDEDGAMES_FROM_HOME:
      statInfo.banner_location = 'home'
      statInfo.section = 'recommended_games'
      statInfo.arg1 = VIEW_ALL
      break
    // App page from Home / 加藤純一アプリ王選手権作品
    case BannerLocation.STAT_APPS_PAGE_TYPEFOR_JUNGAMES_FROM_HOME:
      statInfo.banner_location = 'home'
      statInfo.section = 'jun_games'
      statInfo.arg1 = VIEW_ALL
      break
    // App page from Game Center / Recommended For You
    case BannerLocation.STAT_APPS_PAGE_TYPEFOR_RECOMMENDEDGAMES_FROM_GAMECENTERS:
      statInfo.banner_location = 'game_center'
      statInfo.section = 'recommended_games'
      statInfo.arg1 = VIEW_ALL
      break
    // App page from Game Center / Must-Play Games
    case BannerLocation.STAT_APPS_PAGE_TYPEFOR_MUSTPLAYGAMES_FROM_GAMECENTER:
      statInfo.banner_location = 'game_center'
      statInfo.section = 'must_play_games'
      statInfo.arg1 = VIEW_ALL
      break
    // App page from Game Center / New and Trending
    case BannerLocation.STAT_APPS_PAGE_TYPEFOR_NEWANDTRENDING_FROM_GAMECENTER:
      statInfo.banner_location = 'game_center'
      statInfo.section = 'new_and_trending_games'
      statInfo.arg1 = VIEW_ALL
      break
    // App page from Game Center / Browse By Collection
    case BannerLocation.STAT_APPS_PAGE_TYPEFOR_TOPIC_FROM_ANDROIDGAMES:
      statInfo.banner_location = 'game_center'
      statInfo.section = 'collection_games'
      statInfo.arg1 = Utils.getParamByName('topicId')
      break
    // App page from App Detail / More XXX Apps
    case BannerLocation.STAT_APPS_PAGE_MORE_STRATEGY_GAMES_FROM_APPDETAIL:
      statInfo.banner_location = 'app_page'
      statInfo.section = 'strategy_games'
      statInfo.arg1 = VIEW_ALL
      break
    // App page from App Detail / Similar Games
    case BannerLocation.STAT_APPS_PAGE_SIMILAR_GAMES_FROM_APPDETAIL:
      statInfo.banner_location = 'app_page'
      statInfo.section = 'similar_games'
      statInfo.arg1 = VIEW_ALL
      break
    /**
     * Game center page
     */
    // Recommended For You
    case BannerLocation.STAT_GAMECENTER_PAGE_RECOMMENDED:
      statInfo.banner_location = 'game_center'
      statInfo.section = 'recommended_games'
      break
    // Our New Favorite
    case BannerLocation.STAT_GAMECENTER_PAGE_FEATURE:
      statInfo.banner_location = 'game_center'
      statInfo.section = 'feature_games'
      break
    // Must-Play Games
    case BannerLocation.STAT_GAMECENTER_PAGE_MUSTPLAYGAMES:
      statInfo.banner_location = 'game_center'
      statInfo.section = 'must_play_games'
      break
    // New and Trending
    case BannerLocation.STAT_GAMECENTER_PAGE_NEWANDTRENDING:
      statInfo.banner_location = 'game_center'
      statInfo.section = 'new_and_trending_games'
      break
    /**
     * Home page
     */
    // Home Banner Games
    case BannerLocation.STAT_HOME_PAGE_BANNER:
      statInfo.banner_location = 'home'
      statInfo.section = 'suggested_apps'
      break
    // 加藤純一アプリ王選手権作品
    case BannerLocation.STAT_HOME_PAGE_JUNGAMES:
      statInfo.banner_location = 'home'
      statInfo.section = 'jun_games'
      break
    // Trending Games
    case BannerLocation.STAT_HOME_PAGE_TRENDINGGAMES:
      statInfo.banner_location = 'home'
      statInfo.section = 'trending_games'
      break
    // Popular Games in your region
    case BannerLocation.STAT_HOME_PAGE_POPULARGAMES:
      statInfo.banner_location = 'home'
      statInfo.section = 'popular_games'
      break
    /**
     * App Detail page
     */
    // Banner
    case BannerLocation.STAT_APP_DETAIL_PAGE_BANNER:
      statInfo.banner_location = 'app_page'
      statInfo.section = 'banner'
      break
    // More XXX Apps
    case BannerLocation.STAT_APP_DETAIL_PAGE_MORE_STRATEGY_GAMES:
      statInfo.banner_location = 'app_page'
      statInfo.section = 'strategy_games'
      break
    // Similar Games
    case BannerLocation.STAT_APP_DETAIL_PAGE_SIMILAR_GAMES:
      statInfo.banner_location = 'app_page'
      statInfo.section = 'similar_games'
      break
    /**
     * Google Search page
     */
    // Recent searches Games
    case BannerLocation.STAT_GOOGLE_SEARCH_PAGE_RECENT_SEARCHES:
      statInfo.banner_location = 'google_search'
      statInfo.section = 'recent_searches'
      break
    // Similar Games
    case BannerLocation.STAT_GOOGLE_SEARCH_PAGE_RECOMMENDED:
      statInfo.banner_location = 'google_search'
      statInfo.section = 'recommended_games'
      break
    default:
      break
  }
  return statInfo
}

/**
 * Send a stat
 * @param {string} tableType Stat Table
 * @param {object} values Stat data
 */
const stats = async (tableType, values = {}) => {
  // Add track ID here
  // getTrackId()
  const _values = {}
  for (let key in values) {
    const value = values[key]
    if (typeof value === 'function') {
      try {
        _values[key] = await value(key)
      } catch (error) {}
    } else {
      _values[key] = value
    }
  }
  qwebApi({
    event: 'postStats',
    data: {
      table: tableType,
      data: _values,
    },
  })
}

Object.defineProperty(stats, 'TABLE_TYPE', {
  value: TABLE_TYPE,
  writable: false,
})

Object.defineProperty(stats, 'STATS_POINT', {
  value: STATS_POINT,
  writable: false,
})

export default stats
