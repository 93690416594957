import produce from 'immer'
import * as types from './constant'
import { compareVersionHigh } from '@/tool/version'

const INIT_STATE = {
  isSupportPcVersion: false,
  isSupportAppPage: false
}
export default produce((draft, { type, payload }) => {
  switch (type) {
    case types.SET_INITIAL_SUPPORTS:
      draft.isSupportPcVersion = compareVersionHigh(payload, '0.20.0').compare >= 0
      draft.isSupportAppPage = compareVersionHigh(payload, '0.14.3.5').compare >= 0
      break
    default: {
    }
  }
}, INIT_STATE)
