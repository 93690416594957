import * as types from './constant'

// Set userinfo action
export const setUserInfo = ({ email, token, userid, xtoken }) => {
  return {
    type: types.SET_USER_INFO,
    payload: { email, token, userid, xtoken },
  }
}

// Set theme action
export const setTheme = (theme) => {
  return {
    type: types.SET_THEME,
    payload: theme,
  }
}

// Set client config action
export const setClientUserConfig = (config) => {
  return {
    type: types.CLIENT_USER_CONFIG,
    payload: config,
  }
}
