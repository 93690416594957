import { qwebApi } from '@/apis/qwebApi'
import { __DEV__ } from './qwebchannel/utils'

/**
 * Send logger to client
 * @param {string} type Logger type
 * @param {string} name Logger name
 */
const logger = (type, name) => {
  qwebApi({
    event: 'onWebLogger',
    data: `[booting time][${type}]:${name}`,
  })
  if (__DEV__) {
    if (type === 'start') {
      console.time(`<---${name}--->`)
    } else if (type === 'finish') {
      console.timeEnd(`<---${name}--->`)
    }
  }
}

export default logger
