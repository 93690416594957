// majar number.minor number.stage number.package number
const VersionNames = ['majar', 'minor', 'stage', 'package']
/**
 *  Compare version function
 * @param {string} version Version string, eq: 0.18.10.3
 * @param {string} compareVersion Version to be compared, eq: 0.18.11.3
 * @returns array<boolean>
 */
export const compareVersionFn = (version, compareVersion) => {
  const versionCode = version.split('.').map(Number)
  const compareVersionCode = compareVersion.split('.').map(Number)
  const compareResult = []
  const maxLength = Math.min(versionCode.length, VersionNames.length)
  for (let index = 0; index < maxLength; index++) {
    const diffValue = versionCode[index] - compareVersionCode[index] || 0
    const compareInfo = {
      name: VersionNames[index],
      diffValue,
    }
    if (diffValue > 0) {
      compareInfo.compare = 1
    } else if (diffValue === 0) {
      compareInfo.compare = 0
    } else {
      compareInfo.compare = -1
    }
    compareResult.push(compareInfo)
  }
  return compareResult
}

/**
 * Compare version with provided versions
 * @param {string} version Current version
 * @param {string} compareVersion Compare version
 * @param {boolean|number} index False means compare all versions, Number means only compare index version
 * @returns Whether version is higher then compare version
 */
export const compareVersionHigh = (version = '', compareVersion) => {
  const compareResult = compareVersionFn(version, compareVersion)
  let compareInfo = { diffValue: 0, compare: 0 }
  for (let info of compareResult) {
    if (info.compare === 0) {
      continue
    } else {
      compareInfo = info
      break
    }
  }
  return compareInfo
}
