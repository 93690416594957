

export const getPcDownloadUrlWithTrack = (downloadUrl) => {
  const { launcher_guid = '', launcher_version = '' } = window.__CLIENT_CONFIG__.launcher_info
  const { player_guid = '', player_version = '' } = window.__CLIENT_CONFIG__.player_info
  const isWithParams = downloadUrl.indexOf('?') > -1
  const extraParams = `source=bsx_app_detail~${player_guid}~${player_version}~0~${launcher_guid}~${launcher_version}`
  if (isWithParams) {
    return `${downloadUrl}&${extraParams}`
  } else {
    return `${downloadUrl}?${extraParams}`
  }
}
