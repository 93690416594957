import { useEffect, useCallback } from 'react'
import { on, off } from '@/apis/qwebApi'
import { useHistory } from 'react-router-dom'
const Hook = () => {
  const history = useHistory()
  // On route change
  const onRouter = useCallback(
    ({ action = 'push', ...route }) => {
      history[action](route)
    },
    [history]
  )
  useEffect(() => {
    on('onRoute', onRouter)
    return () => {
      off('onRoute', onRouter)
    }
  }, [onRouter])
}

export default Hook
