import EventEmitter from 'eventemitter3'
import { QWebChannel } from './qwebchannel'
import { assert, isQtClient, __DEV__ } from './utils'

let qWebChannel = null

const conf = {
  timeout: 10000,
}

// Page identifier, can be performed or operated - "indicates that the qwebchannel event is sent to the target page(useless)
export const PageType = {
  Current: 0x0000,
  Home: 0x0001,
  CloudGames: 0x0002,
  AndroidGames: 0x0004,
}

// Request log
const requestLog = (event, data, ga) => {
  if (__DEV__ || window.$$loggerable) {
    try {
      if (ga && Object.keys(ga).length) {
        console.info(
          `%cRequest:qwebApi for <${event}>(Ga-Data):`,
          'color:green;font-size:18px;',
          '\n',
          ga
        )
      }
      if (data) {
        console.info(
          `%cRequest:qwebApi for <${event}>(Req-Data):`,
          'color:pink;font-size:18px;',
          '\n',
          data
        )
      } else {
        console.info(`%cRequest:qwebApi for <${event}>`, 'color:pink;font-size:18px;')
      }
    } catch (error) {}
  }
}

// Response log
const responseLog = (response, isMock) => {
  if (__DEV__ || window.$$loggerable) {
    try {
      const { event, data } = response
      console.info(
        `%cResponse:qwebApi for <${event}>${isMock ? ' [[MOCK-DATA]]' : ''}:`,
        'color:green;font-size:18px;',
        '\n',
        data
      )
    } catch (error) {
      console.error(
        '%cqtContext.contentChanged(Data parsing error)',
        'color:red;font-size:18px;',
        '\n',
        error
      )
    }
  }
}

export const EE = new EventEmitter()
export default function QWC() {
  return new Promise((resolve, reject) => {
    if (!__DEV__) {
      assert(
        window && window.qt && window.qt.webChannelTransport,
        "'qt' or 'qt.webChannelTransport' should be initialized(injected) by QtWebEngine"
      )
    }
    // Used to simulate `Qt` injection behavior when developing on the browser side
    if (!isQtClient) {
      window.qt = {
        webChannelTransport: {
          send() {
            console.log(`QWebChannel simulator activated !`)
            setTimeout(() => {
              qWebChannel.__apis__ = {
                fetchQt: ({ event, data }, { initial }) => {
                  requestLog(event, data)
                  return Promise.resolve(initial)
                },
                fetchQtCallback: (
                  { event, data, callbackEvent = `${event}_callback` },
                  { initial }
                ) => {
                  // initial （initial:when you debug with mock data）
                  return qWebChannel.__apis__.fetchQt({ event, data }, { initial }).then((data) => {
                    responseLog({ event: callbackEvent, data: initial }, true)
                    return data
                  })
                },
                qtContext: null,
                on: EE.on.bind(EE),
                off: EE.off.bind(EE),
                once: EE.off.bind(EE),
                emit: EE.emit.bind(EE),
              }
              resolve(qWebChannel.__apis__)
            }, 0)
          },
        },
      }
    }
    if (!qWebChannel) {
      qWebChannel = new QWebChannel(window.qt.webChannelTransport, (channel) => {
        const qtContext = channel.objects.webBridge
        qtContext.contentChanged.connect((response) => {
          const { event, data } = response
          responseLog({ event, data })
          EE.emit(event, data)
        })
        // ga:Program Dot Statistics
        qWebChannel.__apis__ = {
          // Send a request(only send a request)
          fetchQt: ({ event, data, page = PageType.Current, callbackEvent, ga = {} }) => {
            requestLog(event, data, ga)
            return qtContext.dataChanged(JSON.stringify({ event, data, page, callbackEvent, ga }))
          },
          // Send a request,automatically register `${event}_callback` event to fetch response data
          fetchQtCallback: (
            { event, data, page = PageType.Current, callbackEvent = `${event}_callback`, ga },
            config = {}
          ) => {
            const options = { ...conf, ...config }
            return new Promise((resolve, reject) => {
              let timer = null
              const apiHandler = (data) => {
                clearTimeout(timer)
                timer = null
                resolve(data)
              }
              EE.once(callbackEvent, apiHandler)
              if (options.timeout) {
                timer = setTimeout(() => {
                  EE.off(callbackEvent, apiHandler)
                  reject({ callbackEvent, msg: 'Timeout', time: options.timeout })
                }, options.timeout)
              }
              qWebChannel.__apis__.fetchQt({ event, data, page, callbackEvent, ga })
            })
          },
          qtContext,
          on: EE.on.bind(EE),
          off: EE.off.bind(EE),
          once: EE.off.bind(EE),
          emit: EE.emit.bind(EE),
        }
        resolve(qWebChannel.__apis__)
      })
    } else {
      resolve(qWebChannel.__apis__)
    }
  })
}
