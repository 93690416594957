import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import { qwebApi } from '@/apis/qwebApi'
import rotationCloudApps from './useRotationCloudApps'
import Rate from 'rc-rate'
import 'rc-rate/assets/index.css'
import { useDebounce, useSize } from 'ahooks'
import GameCarouselTwo from '@/components/CarouselSection/GameCarouselSectionForHomeBanner'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import AspectImage from '@/components/common/Image/AspectImage'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import ActionButton from '@/components/actionButton/Index'
import { onGameClickAction } from '@/actions/commonActions'
import SkeletonGameList from '@/skeletons/GameList'
import SkeletonHomeBanner from '@/skeletons/HomeBanner'

import { STAT_HOME_PAGE_BANNER } from '@/tool/constant'

import styles from './banner_v2.module.scss'

const HOME_BANNER_ACTIVE_INDEX_STORAGE_KEY = 'homeBannerActiveIndex'

const Banner = ({ className, gridCount = 4 }) => {
  const rotationBanners = rotationCloudApps()
  const dispatch = useDispatch()
  const [bannerGame, setBannerGame] = useState(null)
  const [isInitPage, setIsInitPage] = useState(true)
  const [activeIndex, setActiveIndex] = useState(
    +window.sessionStorage.getItem(HOME_BANNER_ACTIVE_INDEX_STORAGE_KEY) || 0
  )

  const loadingStore = useSelector((state) => state.loading)
  const installedApps = useSelector((state) => state.app.installedApps)
  const fullscreenState = useSelector((state) => state.system.fullscreenState)
  const isInstalled = installedApps.includes(bannerGame?.package_name)

  const viewportSize = useSize(document.documentElement)

  const debounceSize = useDebounce(viewportSize, { wait: 200 })

  // 打开游戏
  const handleOnClick = useCallback(
    ({ evt, gameType, isInstalled, app, actionCode, redirectCode }) => {
      onGameClickAction({
        app,
        isInstalled,
        evt,
        dispatch,
        gameType,
        bannerLocation: STAT_HOME_PAGE_BANNER,
        actionCode,
        redirectCode,
      })
    },
    [dispatch]
  )

  const subInfoJSX = useMemo(() => {
    if (bannerGame) {
      const segments = []
      if (bannerGame.category) {
        segments.push(<span key={'categery'}>{bannerGame.category}</span>)
      }
      if (bannerGame.platform_name) {
        segments.push(<span key={'platform'}>{bannerGame.platform_name}</span>)
      }
      for (let index = segments.length - 1; index > 0; index--) {
        segments.splice(index, 0, <span key={`split_${index}`} className={styles.split}></span>)
      }
      return segments
    }
    return null
  }, [bannerGame])

  // 鼠标移入事件
  const onHoverEnter = useCallback(
    (item, index) => {
      setActiveIndex(index)
    },
    [setActiveIndex]
  )
  // 页面初始化
  useEffect(() => {
    if (rotationBanners.length) {
      setBannerGame(rotationBanners[activeIndex])
    }
  }, [rotationBanners, activeIndex])

  // 切换banner大图
  useEffect(() => {
    if (bannerGame) {
      let top = 0
      const homeContainerWrap = document.querySelector('.fullScreenScrollBody')
      if (homeContainerWrap) {
        top = window.getRealMeasurement(homeContainerWrap.scrollTop)
      }
      qwebApi({
        event: 'changeBannerBackgroundImage',
        data: {
          url: bannerGame.full_hd_image,
          video: bannerGame.video_url,
          playCount: 1,
          sleep: 500,
          tileRatio: 0.6,
          type: 'position',
          top,
        },
      })
    } else {
      qwebApi({ event: 'changeBannerBackgroundImage' })
    }
  }, [bannerGame])

  useEffect(() => {
    window.sessionStorage.setItem(HOME_BANNER_ACTIVE_INDEX_STORAGE_KEY, activeIndex)
  }, [activeIndex])

  useEffect(() => {
    try {
      const dom = document.getElementById('homeBannerApps')
      const homeContainerWrap = document.querySelector('.fullScreenScrollBody')
      if (dom && homeContainerWrap) {
        const domRect = dom.getBoundingClientRect()
        qwebApi({
          event: 'onHomeBannerSizeChange',
          data: {
            left: window.getRealMeasurement(domRect.x + 23 + homeContainerWrap.scrollLeft),
            top: window.getRealMeasurement(
              domRect.y + 50 + domRect.height / 3 + homeContainerWrap.scrollTop
            ),
          },
        })
      }
    } catch (error) {
      console.log(error)
    }
  }, [debounceSize.width, rotationBanners.length])

  const WidthHeightRatio = window.innerWidth / window.innerHeight
  const showFullscreen = useMemo(() => {
    return fullscreenState && WidthHeightRatio <= 2.6504065040650406
  }, [fullscreenState, WidthHeightRatio])

  return (
    <div
      className={classNames([
        styles.container,
        className,
        showFullscreen ? styles.fullscreenState : '',
      ])}
      style={{
        height: showFullscreen
          ? `${window.getZoomHeight(WidthHeightRatio < 2.1 ? 0.75 : 1)}px`
          : 'auto',
      }}
    >
      <div className={styles.emptyBlock}></div>
      {/* <SkeletonHomeBanner/> */}
      {bannerGame ? (
        <div className={classNames(styles.description)}>
          <div className={classNames([styles.subDesc])}>
            <AspectImage
              className={styles.subDescLeft}
              src={bannerGame.icon_url}
              alt="homePageBannerImage"
            />
            <div className={styles.subDescRight}>
              <div className={styles.appRate}>
                <Rate
                  disabled
                  defaultValue={0}
                  value={parseFloat(bannerGame.app_rating) || 0}
                  style={{ fontSize: 14 }}
                  allowHalf
                  character={<SvgIcon size={1} icon="star" />}
                  allowClear={false}
                />
              </div>
              <div className={styles.appInfo}>{subInfoJSX}</div>
            </div>
          </div>
          <div className={classNames(styles.title)}>{bannerGame.game_name}</div>
          <div className={styles.actionContainer}>
            <ActionButton
              app={bannerGame}
              // size={fullscreenState ? 'small' : 'default'}
              isInstalled={isInstalled}
              handleOnClick={handleOnClick}
            />
          </div>
        </div>
      ) : (
        <SkeletonHomeBanner enable={loadingStore.gameCenterRotationLoading} />
      )}
      {rotationBanners.length ? (
        <ErrorFallback>
          <GameCarouselTwo
            className={styles.bannerList}
            carouselKeyId="homeBannerGames"
            list={rotationBanners}
            imgPlaceholderClass="ratio-1-1"
            bannerLocation={STAT_HOME_PAGE_BANNER}
            showCloudBtn
            showPlayBtn
            showInstalledState
            showDetailIcon={false}
            slidesPerView={gridCount + 1}
            onHoverEnter={onHoverEnter}
            // onItemClick={onHoverEnter}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            slideConfig={{
              onActiveIndexChange: (swiper) => {
                if (!isInitPage) {
                  setActiveIndex(swiper.activeIndex)
                }
              },
              onAfterInit: (swiper) => {
                console.log(activeIndex, 'activeIndex')
                setTimeout(() => {
                  swiper.slideTo(activeIndex, 0)
                  setIsInitPage(false)
                }, 100)
              },
            }}
          />
        </ErrorFallback>
      ) : (
        <SkeletonGameList
          className={styles.skeletonContainer}
          column={gridCount + 1}
          showHeader={false}
          gameItemProps={{ showSubTitle: false }}
          enable={loadingStore.gameCenterRotationLoading}
        />
      )}
    </div>
  )
}

export default Banner
