import React from 'react'
// import { useSelector } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Switch, Route } from 'react-router-dom'
// import { usePrevious } from 'ahooks'
import Utils from '@/Utils'
import { qwebApi } from '@/apis/qwebApi'
// import { removeTrackId } from '@/tool/track'
// import { inactive, getRouteState, isActive } from '@/tool/clientRouteState'
// import { memoryCache } from '@/hook/useScrollMemory'
import './index.css'
const ANIMATION_MAP = {
  PUSH: 'translateX',
  POP: 'reTranslateX',
  REPLACE: 'reTranslateX',
}
function Animate({ children }) {
  // const location = useLocation()
  // const prevPathname = usePrevious(location.pathname)
  // const pageLocation = useSelector((state) => state.system.location)
  // useEffect(() => {
  //   const unListen = history.listen(({ pathname }) => {
  //     // When enter the page that pathname is same as paths below, remove trackId and re-generate trackId for stat.
  //     const path = pathname.substring(1)
  //     if (['gameCenter', 'home', 'search'].includes(path)) {
  //       removeTrackId()
  //     }
  //     const routeStateisActive = isActive()
  //     if (routeStateisActive) {
  //       inactive()
  //     }
  //     if (path !== pageLocation) {
  //       qwebApi({ event: 'changeBannerBackgroundImage' })
  //     }
  //     if (routeStateisActive) {
  //       const scrollContainer = document.querySelector('.fullScreenScrollBody')
  //       if (scrollContainer) {
  //         scrollContainer.scrollTo({ top: 0 })
  //       } else {
  //         setTimeout(() => {
  //           document.querySelector('.fullScreenScrollBody').scrollTo({ top: 0 })
  //         }, 400)
  //       }
  //     }
  //   })
  //   return () => unListen()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pageLocation])
  return (
    <Route
      render={({ location, history }) => (
        <TransitionGroup
          className="contentInner"
          childFactory={(child) => {
            const animation = Utils.getParamByName('animation')
            let classNames = ANIMATION_MAP[history.action]
            if (animation && history.action === 'PUSH') {
              classNames = animation
            }
            return React.cloneElement(child, {
              classNames,
            })
          }}
        >
          <CSSTransition
            timeout={300}
            key={location.pathname}
            onExit={() => {
              qwebApi({ event: 'changeBannerBackgroundImage' })
            }}
            // onEntering={()=>{
            //   const isOnAppDetail = matchPath(prevPathname, { path: '/app/:packageName' })
            //   if (isOnAppDetail) {
            //     memoryCache.delete('home_v2')
            //   }
            // }}
            // onEntered={(node) => {
            //   const isOnAppDetail = matchPath(prevPathname, { path: '/app/:packageName' })
            //   if (isOnAppDetail) {
            //     const scrollContainer = document.querySelector('.fullScreenScrollBody')
            //     if (scrollContainer) {
            //       scrollContainer.scrollTo({ top: 0 })
            //     }
            //   }
            //   // node.scrollTo({ top: 0 })
            // }}
          >
            <Switch location={location}>{children}</Switch>
          </CSSTransition>
        </TransitionGroup>
      )}
    ></Route>
  )
}

export default Animate
