import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { getButtonText } from '@/tool/buttonText'
const Hook = (app) => {
  const intl = useIntl()
  const installedApps = useSelector((state) => state.app.installedApps)
  const oneButtonConfig = useSelector((state) => state.user.clientUserConfig.one_button_config)
  const localPerference = useSelector((state) => state.app.localPerference[app.package_name])
  const isInstalled = installedApps.includes(app.package_name)
  const { gameType, actionCode, actionText, redirectCode } = getButtonText(intl, {
    isInstalled,
    app,
    platforms: localPerference,
    oneButtonConfig,
  })
  let _actionCode = actionCode
  let _gameType = gameType
  // Support cloud game and the configuration is based on user selection
  if (app.cloud_supported && oneButtonConfig !== 0) {
    _actionCode = ''
    _gameType = ''
  }
  return { gameType: _gameType, actionCode: _actionCode, actionText, isInstalled, redirectCode }
}

export default Hook
