/**
 * 轮播组件使用Home使用
 */

import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import Carousel from '@/components/Carousel/Index'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import GameListItem from '../common/GameListItem/GameListItem'
import './index.scss'
const GameCarouselSection = ({
  className,
  list,
  // slider组件props
  slideConfig = {},
  carouselKeyId,
  slidesPerView,
  slidesPerGroup,
  // GameListItem组件props
  showSubTitle = false,
  showCloudBtn,
  showPlayBtn,
  showPcBtn,
  showDeleteBtn,
  showTypeIcon,
  showDetailIcon,
  onItemClick = () => { },
  showInstalledState,
  showLikeIncon,
  showCsIcon = true,
  imgPlaceholderClass,
  imageUrlGet,
  bannerLocation,
  bootGameOnClick,
  hasHoverAction = true,
  onHoverEnter,
  // Image组件props
  lazyRootMargin = '0px',
  onImageLoad,
  onImageError,
  imageDisplayStrategy,

  renderItem = null,
  activeIndex = 0,
  setActiveIndex,
}) => {
  const itemProps = useMemo(() => {
    return {
      showSubTitle,
      showCsIcon,
      bootGameOnClick,
      imgPlaceholderClass,
      bannerLocation,
      showInstalledState,
      onImageError,
      onImageLoad,
      imageDisplayStrategy,
      lazyRootMargin,
      showTypeIcon,
      showLikeIncon,
      showPcBtn,
      showCloudBtn,
      showPlayBtn,
      showDeleteBtn,
      showDetailIcon,
      onItemClick,
      imageUrlGet,
      onHoverEnter,
      hasHoverAction,
    }
  }, [
    showSubTitle,
    showCsIcon,
    bootGameOnClick,
    imgPlaceholderClass,
    bannerLocation,
    showInstalledState,
    onImageError,
    onImageLoad,
    imageDisplayStrategy,
    lazyRootMargin,
    showTypeIcon,
    showLikeIncon,
    showPcBtn,
    showCloudBtn,
    showPlayBtn,
    showDeleteBtn,
    showDetailIcon,
    onItemClick,
    imageUrlGet,
    onHoverEnter,
    hasHoverAction,
  ])

  const renderChildJsx = useCallback(
    (slide, index) => {
      if (renderItem) {
        return renderItem({ slide, itemProps, index })
      }
      const _onHoverEnter = itemProps.onHoverEnter
        ? (app, swiper, swiperSlide) => {
          itemProps.onHoverEnter(app, index, swiper, swiperSlide)
          setActiveIndex(index)
        }
        : null
      return (
        <GameListItem
          key={slide.package_name}
          {...itemProps}
          onHoverEnter={_onHoverEnter}
          onClick={itemProps.onItemClick}
          selfLazyLoad={false}
          itemIndex={index}
          nameClass={`font16`}
          categoryClass="font14 opaqueWhiteText"
          game={slide}
          className={classNames([
            'game-carousel-section-carouselItem',
            { 'game-carousel-section-activeItem': activeIndex === index },
          ])}
        />
      )
    },
    [renderItem, itemProps, setActiveIndex, activeIndex]
  )
  const renderSwitchJSX = useCallback(({ nextElClassName, prevElClassName }) => {
    return (
      <div className="game-carousel-section-homeBannerSwitch">
        <div className="switchLeft">
          <SvgIcon key="prev" size={1} icon="prev" className={['switchIcon', prevElClassName]} />
        </div>
        <div className="switchRight">
          <SvgIcon key="next" size={1} icon="next" className={['switchIcon', nextElClassName]} />
        </div>
      </div>
    )
  }, [])
  if (list.length === 0) {
    return null
  }
  return (
    <Carousel
      carouselKeyId={carouselKeyId}
      list={list}
      id="homeBannerApps"
      className={['game-carousel-section', 'game-carousel-section-homeBanner', className]}
      slideConfig={slideConfig}
      slidesPerView={slidesPerView}
      slidesPerGroup={slidesPerGroup}
      renderHeader={renderSwitchJSX}
    >
      {renderChildJsx}
    </Carousel>
  )
}

export default GameCarouselSection
