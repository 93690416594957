import React, { useRef, useMemo, useImperativeHandle } from 'react'
import classNames from 'classnames'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import BaseHeader from '@/components/common/Heading/BaseHeader'
import Heading from '@/components/common/Heading/Heading'

import useScrollBarActive from '@/hook/useScrollBarActive'

import './index.scss'
const PageLayout = (
  {
    onScroll,
    children,
    onBack,
    renderHeaderLeft = null,
    renderHeaderRight = null,
    titleId = 'goBack',
    title,
    className,
    renderHeader = null,
  },
  ref
) => {
  const contentRef = useRef(null)
  useScrollBarActive(contentRef, onScroll)

  const _renderDefaultHeaderLeft = useMemo(() => {
    const _onBack = onBack || function () {}
    return (
      <div onClick={_onBack} className="pageLayoutHeaderLeftWrap">
        <SvgIcon className="headerBackIcon" size={1} icon="go-back" />
        <Heading title={title} titleId={titleId} />
      </div>
    )
  }, [onBack, titleId, title])

  useImperativeHandle(ref, () => {
    return {
      getScrollTop: () => contentRef.current?.scrollTop || 0,
    }
  })

  return (
    <div className={classNames(['pageLayout', className])}>
      <BaseHeader
        className="pageLayoutHeader"
        title={title}
        titleId={titleId}
        renderLeft={renderHeaderLeft || _renderDefaultHeaderLeft}
        renderRight={renderHeaderRight}
        renderContent={renderHeader}
        type="page"
      />
      <div className="pageLayoutContent" ref={contentRef}>
        {children}
      </div>
    </div>
  )
}

export default React.forwardRef(PageLayout)
