import React from 'react'
import RcTooltip from '@/components/tooltip/RcTooltip'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import Heading from '@/components/common/Heading/Heading'
import './refreshIcon.scss'
const RefreshIcon = ({ onRefresh, title, titleId = 'shuffleTip' }) => {
  return (
    <RcTooltip
      placement="right"
      key="cloudButtonTooltip"
      trigger="hover"
      overlay={<Heading title={title} titleId={titleId} />}
    >
      <SvgIcon onClick={onRefresh} key="shuffle" size={1} icon="shuffle" className="refresh-icon" />
    </RcTooltip>
  )
}

export default RefreshIcon
