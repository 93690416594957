/**
 * Game carousel module
 */

import React, { useCallback, useMemo } from 'react'
import CommonCarouselSection from './CommonCarouselSection'
import GameListItem from '@/components/common/GameListItem/GameListItem'
import { loop } from '@/tool'
const GameCarouselSection = ({
  title,
  titleId,
  className,
  linkTo,
  pagination = true,
  onRefresh,
  list,
  linkToText = 'viewAll',
  titleClass,
  // slider组件props
  slideConfig,
  carouselKeyId,
  slidesPerView,
  slidesPerGroup,
  // GameListItem组件props
  showCloudBtn,
  showPlayBtn,
  showPcBtn,
  showDeleteBtn,
  showTypeIcon,
  onItemClick = loop,
  showInstalledState,
  showLikeIncon,
  imgPlaceholderClass,
  imageUrlGet,
  bannerLocation,
  bootGameOnClick,
  // Image组件props
  lazyRootMargin = '0px',
  onImageLoad,
  onImageError,

  groupNumber = 0,
  renderItem = null,
  isGroup = true,
  gap,
}) => {
  const itemProps = useMemo(() => {
    return {
      bootGameOnClick,
      imgPlaceholderClass,
      bannerLocation,
      showInstalledState,
      onImageError,
      onImageLoad,
      lazyRootMargin,
      showTypeIcon,
      showLikeIncon,
      showPcBtn,
      showCloudBtn,
      showPlayBtn,
      showDeleteBtn,
      onItemClick,
      imageUrlGet,
    }
  }, [
    bootGameOnClick,
    imgPlaceholderClass,
    bannerLocation,
    showInstalledState,
    onImageError,
    onImageLoad,
    lazyRootMargin,
    showTypeIcon,
    showLikeIncon,
    showPcBtn,
    showCloudBtn,
    showPlayBtn,
    showDeleteBtn,
    onItemClick,
    imageUrlGet,
  ])

  const renderChildJsx = useCallback(
    (slide, index) => {
      if (renderItem) {
        return renderItem({ slide, itemProps, index })
      }
      const renderSlides = isGroup ? slide : [slide]
      return renderSlides.map((item) => {
        return (
          <GameListItem
            key={item.package_name}
            {...itemProps}
            onClick={itemProps.onItemClick}
            selfLazyLoad={false}
            nameClass={`font16`}
            categoryClass="font14 opaqueWhiteText"
            game={item}
          />
        )
      })
    },
    [renderItem, itemProps, isGroup]
  )
  if (list.length === 0) {
    return null
  }
  return (
    <CommonCarouselSection
      carouselKeyId={carouselKeyId}
      list={list}
      className={['game-carousel-section', className]}
      slideConfig={slideConfig}
      slidesPerView={slidesPerView}
      slidesPerGroup={slidesPerGroup}
      linkTo={linkTo}
      title={title}
      titleId={titleId}
      pagination={pagination}
      onRefresh={onRefresh}
      linkToText={linkToText}
      groupNumber={groupNumber}
      isGroup={isGroup}
      gap={gap}
      titleClass={titleClass}
    >
      {renderChildJsx}
    </CommonCarouselSection>
  )
}

export default GameCarouselSection
