import { v4 as uuid } from 'uuid'
let trackID = ''

/**
 * Get track ID, if none that create a new track ID
 * @returns Track ID
 */
export const getTrackId = () => {
  return trackID || createTrackId()
}

/**
 * Create a new track ID
 * @param {string} id Track ID
 * @returns New track ID
 */
export const createTrackId = (id) => {
  trackID = id || uuid()
  return trackID
}

/**
 * Set track ID
 * @param {string} id Track ID
 */
export const setTrackId = (id) => {
  trackID = id
}

/**
 * Remove track ID
 */
export const removeTrackId = () => {
  trackID = ''
}
