import React, { useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  Player,
  ControlBar,
  BigPlayButton,
  PlayToggle,
  VolumeMenuButton,
  Shortcut,
} from 'video-react'
import SwiperCore, { EffectFade } from 'swiper'
import BaseHeader from '@/components/common/Heading/BaseHeader'
import style from './gameFeature.module.scss'
import { useInViewport } from 'ahooks'
SwiperCore.use([EffectFade])

const GameFeature = ({ className, features, slideConfig = {} }) => {
  const slider = useRef(null)
  const containerRef = useRef(null)
  const playerRef = useRef([])
  const isInViewport = useInViewport(containerRef)

  // 当前活动tab
  const [activeIndex, setActiveIndex] = useState(0)
  // 轮播sliders
  const sliderList = useMemo(() => {
    const list = []
    Object.keys(features).forEach((key) => {
      const keyFeatures = features[key].filter((feat) => feat.terms_data)
      list.push(...keyFeatures)
    })
    return list
  }, [features])

  // swiper配置参数
  const settings = useMemo(() => {
    return {
      autoHeight: true,
      effect: 'fade',
      simulateTouch: false,
      spaceBetween: 20,
      onAfterInit: (swiper) => {
        slider.current = swiper
      },
    }
  }, [slider])

  useEffect(() => {
    if (slider.current) {
      slider.current.slideTo(activeIndex)
      Object.keys(playerRef.current).forEach((index) => {
        if (index !== activeIndex) {
          const player = playerRef.current[index]
          player.pause()
        }
      })
      if (playerRef.current[activeIndex] && isInViewport) {
        if (isInViewport) {
          window.aa = playerRef.current[activeIndex]
          // playerRef.current[activeIndex].play()
          setTimeout(() => {
            playerRef.current[activeIndex].play()
          }, 100)
        }
      }
    }
  }, [activeIndex, isInViewport])

  return (
    <div className={classNames([style.container, className])}>
      <BaseHeader className={style.header} titleId="gameFeatures" />
      <div className={style.content}>
        <div className={style.contentLeft}>
          {Object.keys(features).map((featureKey) => {
            const featureContents = features[featureKey].filter((feat) => {
              return Boolean(feat?.terms_data?.term_id)
            })
            if (featureContents.length === 0) {
              return null
            }
            return (
              <div className={style.tabContainer} key={featureKey}>
                <div className={style.tabHeader}>
                  <FormattedMessage id={featureKey} />
                </div>
                <ul className={style.tabContent}>
                  {featureContents.map((content) => {
                    const { terms_data } = content
                    const sliderIndex = sliderList.findIndex(
                      (slider) => slider.terms_data?.term_id === terms_data.term_id
                    )
                    const isActive = sliderIndex === activeIndex
                    return (
                      <li
                        className={classNames([
                          style.tabContentItem,
                          'opaqueWhiteText',
                          { [style.tabContentItemActive]: isActive },
                        ])}
                        tab={terms_data.title_copy}
                        key={terms_data.term_id}
                        onClick={() => setActiveIndex(sliderIndex)}
                      >
                        {terms_data.title_copy}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
        </div>
        <div className={style.contentRight} ref={containerRef}>
          <Swiper {...settings} {...slideConfig} className="gameFeatureSwiper">
            {sliderList.map((feature, index) => {
              const { terms_data, custom_video, custom_video_thumb } = feature
              const isVideo = Boolean(custom_video || terms_data.video)
              return (
                <SwiperSlide key={terms_data.term_id}>
                  <div className={style.activeContainer}>
                    {isVideo ? (
                      <Player
                        ref={(player) => (playerRef.current[index] = player)}
                        poster={custom_video_thumb || terms_data.video_thumb}
                        src={custom_video || terms_data.video}
                        preload="metadata"
                        // autoPlay
                        loop
                        // muted={'muted'}
                      >
                        <BigPlayButton position="center" />
                        <Shortcut clickable={false} dblclickable={false} />
                        <ControlBar
                          autoHideTime={1000}
                          disableDefaultControls={true}
                          disableCompletely={false}
                        >
                          <PlayToggle />
                          <VolumeMenuButton />
                        </ControlBar>
                      </Player>
                    ) : (
                      <img src={terms_data.banner} alt={terms_data.banner} />
                    )}
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default React.memo(GameFeature)
