import React, { useContext, useState, useEffect, useMemo } from 'react'

const ViewportContext = React.createContext({})

export const SizeMap = {
  sm: 'sm',
  sx: 'sx',
  md: 'md',
  lg: 'lg',
  llg: 'llg',
}
const defaultPoints = {
  // 980
  [SizeMap.sx]: 868,
  // 1280
  [SizeMap.sm]: 1168,
  // 1366
  [SizeMap.md]: 1254,
  // 1600
  [SizeMap.lg]: 1488,
  // 1920
  [SizeMap.l9g]: 1808,
}

const getGridCount = (width = window.innerWidth) => {
  const deviceWidth = width * window.devicePixelRatio
  let count = 5
  if (deviceWidth >= defaultPoints[SizeMap.sx]) {
    count = 6
  }

  // if (deviceWidth >= defaultPoints[SizeMap.sm]) {
  //   count = 5
  // }

  // if (deviceWidth >= defaultPoints[SizeMap.md]) {
  //   count = 5
  // }

  if (deviceWidth >= defaultPoints[SizeMap.lg]) {
    count = 7
  }

  if (deviceWidth >= defaultPoints[SizeMap.llg]) {
    count = 8
  }
  return count
}

export const ViewportProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    const onWindowResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', onWindowResize, false)
    return () => window.removeEventListener('resize', onWindowResize)
  }, [])

  return <ViewportContext.Provider value={{ width, height }}>{children}</ViewportContext.Provider>
}
export const usePxToPx = (len) => {
  const { width } = useContext(ViewportContext)
  const [length, setLength] = useState(len)
  useEffect(() => {
    setLength(window.usePxToPx(len))
  }, [width, len])
  return length
}

export const useGridBaseCount = () => {
  const [baseCount, setBaseCount] = useState(getGridCount())
  const { width } = useContext(ViewportContext)
  useEffect(() => {
    const count = getGridCount(width)
    setBaseCount(count)
  }, [width])
  return baseCount
}
const Hook = (breakpoints = defaultPoints) => {
  const { width, height } = useContext(ViewportContext)
  const zoomWidth = width * window.devicePixelRatio
  const zoomHeight = height * window.devicePixelRatio
  const point = useMemo(() => {
    if (zoomWidth >= breakpoints[SizeMap.lg]) {
      return SizeMap.lg
    }
    if (zoomWidth >= breakpoints[SizeMap.md]) {
      return SizeMap.md
    }
    if (zoomWidth >= breakpoints[SizeMap.sm]) {
      return SizeMap.sm
    }
    if (zoomWidth <= breakpoints[SizeMap.sx]) {
      return SizeMap.sx
    }
  }, [zoomWidth, breakpoints])
  return { width, height, point, zoomWidth, zoomHeight }
}

export default Hook
