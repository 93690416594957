import { useEffect, useState, useCallback } from 'react'
import { on, off, qwebApi } from '@/apis/qwebApi'
const Hook = () => {
  const [installedGames, setInstalledGames] = useState([])
  // Get installed apps
  useEffect(() => {
    qwebApi({ event: 'getInstalledPcGames', callbackEvent: 'updateInstalledPcGames' })
  }, [])
  // On update
  const onUpdateInstalledPcGames = useCallback(
    (jsonObject) => {
      try {
        const { action, package_name, apps } = jsonObject
        const isExist = installedGames.includes(package_name)
        let allApps = [...installedGames]
        if (action === 'install' && !isExist) {
          allApps.push(package_name)
        }
        if (action === 'uninstall' && isExist) {
          allApps = allApps.filter((name) => name !== package_name)
        }
        if (action === 'all') {
          allApps = apps.map((app) => app.package_name)
        }
        setInstalledGames(allApps)
      } catch (error) {}
    },
    [installedGames]
  )
  // Register event for installed apps
  useEffect(() => {
    on('updateInstalledPcGames', onUpdateInstalledPcGames)
    return () => {
      off('updateInstalledPcGames', onUpdateInstalledPcGames)
    }
  }, [onUpdateInstalledPcGames])
  return installedGames
}
export default Hook
