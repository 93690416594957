/**
 * Judge the url whether is youtube link
 * @param {string} url Url
 * @returns boolean
 */
export const isYoutubeUrl = (url) => {
  if (url && typeof url === 'string') {
    return url.startsWith('https://www.youtube.com')
  }
  return false
}

/**
 * Convert to embeddable youtube link
 * @param {string} url Url that to be convert
 * @returns string
 */
export const transformToEmbedUrl = (url) => {
  if (isYoutubeUrl(url)) {
    return url.replace(/\/watch\?v=/, '/embed/')
  }
  throw new Error('Invalid youtube url.')
}
