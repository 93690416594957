import produce from 'immer'
import * as types from './constant'

const INIT_STATE = {
  // App detail info map
  map: {},
  // App info list stack(for cache)
  appHeaps: [],
}

export default produce((draft, action) => {
  const { type, payload } = action
  switch (type) {
    case types.SET_APP_DETAIL: {
      const { key, value } = payload
      draft.map[key] = value
      break
    }
    case types.PUSH_APP_STACK: {
      const { $$isClear, ...app } = payload
      if ($$isClear) {
        draft.appHeaps = [app]
      } else {
        draft.appHeaps.push(app)
      }
      break
    }
    case types.POP_APP_STACK: {
      draft.appHeaps.pop()
      break
    }
    default: {
    }
  }
}, INIT_STATE)
