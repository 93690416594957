import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import AspectImage from '@/components/common/Image/AspectImage'
import { onGameClickAction } from '@/actions/commonActions'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import ActionButton from '@/components/actionButton/Index'
import './advertisement.scss'
import useAppLaunchParams from '@/hook/useAppLaunchParams'
const Advertisement = ({
  app,
  showInstalledState,
  imgPlaceholderClass,
  bannerLocation,
  className,
}) => {
  const countryCode = useSelector((state) => state.system.countryCode)
  const { gameType, actionCode, isInstalled } = useAppLaunchParams(app)

  // 点击游戏按钮
  const handleOnClick = useCallback(
    ({ app, evt, gameType, actionCode, redirectCode }) => {
      onGameClickAction({
        app,
        isInstalled,
        evt,
        gameType,
        bannerLocation,
        actionCode,
        redirectCode,
      })
    },
    [bannerLocation, isInstalled]
  )
  // 是否显示查看详情按钮
  const hasDetailAction = useMemo(() => {
    if (!app.isAppDetailAccessable || countryCode === 'JP') {
      return false
    }
    return true
  }, [app, countryCode])

  return (
    <div
      className={classNames([
        'advertisement-container',
        {
          [imgPlaceholderClass]: imgPlaceholderClass,
          'responsive-container': imgPlaceholderClass,
        },
        className,
      ])}
      style={{
        backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0,0,0,0.8) 47%, rgba(0,0,0,0.4) 79%, rgba(0,0,0,0.3) 100%),url(${app.banner_url})`,
      }}
    >
      {/* 小图片 */}
      <div className="advertisement-containerInner">
        {/* 左边 */}
        <AspectImage className="advertisement-smallIconContainer" src={app.icon_url}>
          {/* 已安装 */}
          {showInstalledState && isInstalled && (
            <SvgIcon size={1} icon="installed" className="installed" />
          )}
          {hasDetailAction && (
            <div
              className="advertisement-detailClickPanel"
              onClick={(evt) =>
                handleOnClick({ app, evt, gameType, actionCode, redirectCode: 'AppDetail' })
              }
            >
              <SvgIcon size={1} icon="zoom-in" />
            </div>
          )}
        </AspectImage>
        {/* 右边 */}
        <div className="advertisement-appDetails">
          {!app.isOpenInBrowser && (
            <p className="advertisement-availableText">
              <FormattedMessage id="availableNow"></FormattedMessage>
            </p>
          )}
          <p className="advertisement-appName">{app.game_name}</p>
          {app.platform_name && <p className="advertisement-platformName">{app.platform_name}</p>}
          {/* 操作按钮 */}
          <ActionButton
            className="advertisement-bannerAction"
            app={app}
            isInstalled={isInstalled}
            handleOnClick={handleOnClick}
          />
        </div>
      </div>
    </div>
  )
}

export default Advertisement
