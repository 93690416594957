import produce from 'immer'
import * as types from './constant'

const INIT_STATE = {
  gameCenterRotationLoading: false,
  gameCenterHomeLoading: false,
  cpiAppsForQuestLoading: false,
  cloudGameLoading: false,
  appPageDetailsLoading: false,
  appLauncherSearchLoading: false,
  topicDatasLoading: false,
  suggestedAppsLoading: false,
  sidePanelLoading: false,
}
const userReducer = produce((state, action) => {
  const { type, payload } = action
  switch (type) {
    case types.SET_GAME_CENTER_ROTATION_LOADING: {
      state.gameCenterRotationLoading = payload
      break
    }
    case types.SET_GAME_CENTER_HOME_LOADING: {
      state.gameCenterHomeLoading = payload
      break
    }
    case types.SET_CPI_APPS_FOR_REQUEST_LOADING: {
      state.cpiAppsForQuestLoading = payload
      break
    }
    case types.SET_CLOUD_LOADING_LOADING: {
      state.cloudGameLoading = payload
      break
    }
    default: {
    }
  }
}, INIT_STATE)

export default userReducer
