import { useEffect, useLayoutEffect, useRef } from 'react'

import { containerValueFinder } from '@/tool'

export const memoryCache = {
  store: {},
  get(key) {
    return memoryCache.store[key]
  },
  set(key, value) {
    memoryCache.store[key] = value
    return Object.keys(memoryCache.store)
  },
  delete(key) {
    delete memoryCache.store[key]
    return true
  },
}
const defaultOptions = {
  // Whether to enable smooth mode
  smooth: false,
  // store
  store: memoryCache,
  // cache key
  key: 'cacheKey',
  // maximum number of attempts
  maxHit: 20,
  // Enable
  enable: true,
}

const Hook = (ref, options) => {
  const optionsRef = useRef({
    ...defaultOptions,
    ...options,
  })

  // Record the location before uninstalling
  useLayoutEffect(() => {
    const { store, key, enable } = optionsRef.current
    let scrollContainer = null
    if (enable) {
      scrollContainer = containerValueFinder(ref)
      console.log('useScrollMemory-', key, '-', 1)
    }
    return () => {
      if (enable) {
        const { scrollTop, scrollLeft } = scrollContainer
        store.set(key, {
          scrollTop,
          scrollLeft,
        })
        console.log('useScrollMemory-', key, '-', 2)
      }
    }
  }, [ref])

  // Restore position
  useEffect(() => {
    const { smooth, store, key, maxHit, enable } = optionsRef.current
    let isValid = true
    let cancelRequestFrame = null
    let hitCount = 0
    let positionInfo = {
      top: 0,
      left: 0,
    }
    if (enable) {
      const scrollContainer = containerValueFinder(ref)
      const cachePosition = store.get(key)
      if (cachePosition) {
        positionInfo = {
          left: cachePosition.scrollLeft,
          top: cachePosition.scrollTop,
        }
      }
      const runFrame = () => {
        if (isValid) {
          if (
            scrollContainer.scrollTop < positionInfo.top ||
            scrollContainer.scrollLeft < positionInfo.left
          ) {
            scrollContainer.scrollTo({
              ...positionInfo,
              behavior: smooth ? 'smooth' : 'auto',
            })
            if (++hitCount <= maxHit) {
              cancelRequestFrame = requestAnimationFrame(runFrame)
            }
          }
        }
      }
      cancelRequestFrame = requestAnimationFrame(runFrame)
      console.log('useScrollMemory-', key, '-', 3)
    }
    return () => {
      if (enable) {
        isValid = false
        cancelAnimationFrame(cancelRequestFrame)
        console.log('useScrollMemory-', key, '-', 4)
      }
    }
  }, [ref])
}

export default Hook
