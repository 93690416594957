import produce from 'immer'
import * as types from './constant'

const INIT_STATE = {
  localPerference: {},
  // Installed app
  installedApps: [],
  // Installed pc game
  installedPcGames: [],
  // Rotation apps
  rotationBanners: [],
  // Recommended apps
  recommendedApps: [],
  // Hot apps
  hotApps: [],
  // Top apps
  topApps: [],
  // Side_panel apps(useless)
  sidepanel: {
    apps: [],
    tallBanners: [],
  },
  // Topic list
  topics: [],
  // {apps,topicId,info}
  topicAppsMap: {},
  // Favorite app
  featureApps: [],
  // Suggested apps
  suggestedApps: [],
}

export default produce((draft, action) => {
  const { type, payload } = action
  switch (type) {
    // Installed apps(only package name)
    case types.SET_INSTALLED_APPS: {
      draft.installedApps = payload
      break
    }
    // Installed pc games(only package name)
    case types.SET_INSTALLED_PC_GAMES: {
      draft.installedPcGames = payload
      break
    }
    // Rotation apps
    case types.FETCH_ROTATION_APPS: {
      draft.rotationBanners = payload
      break
    }
    // By appcenter/game_center_home API
    case types.FETCH_GAME_CENTER_HOME_APPS: {
      const { recommendedApps, hotApps, topApps, topics } = payload
      draft.recommendedApps = recommendedApps
      draft.hotApps = hotApps
      draft.topApps = topApps
      draft.topics = topics
      break
    }
    case types.SHUFFLE_RECOMMENDED_APPS: {
      draft.recommendedApps = payload
      break
    }
    // Set feature apps
    case types.FETCH_FEATURED_APPS: {
      draft.featureApps = payload
      break
    }
    // Set topic apps
    case types.SET_TOPIC_APPS: {
      const { topicId } = payload
      draft.topicAppsMap[topicId] = payload
      break
    }
    // Set side_panel apps
    case types.FETCH_SIDEPANEL_APPS: {
      const { apps, tallBanners } = payload
      draft.sidepanel.apps = apps
      draft.sidepanel.tallBanners = tallBanners
      break
    }
    // local perference
    case types.SET_LOCAL_PERFERENCE: {
      draft.localPerference = payload
      break
    }
    // suggested apps
    case types.FETCH_SUGGESTED_APPS: {
      draft.suggestedApps = payload
      break
    }
    default: {
    }
  }
}, INIT_STATE)
