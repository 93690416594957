import produce from 'immer'
import * as types from './constant'

const INIT_STATE = {
  cloudGames: [],
}
export default produce((state, { type, payload }) => {
  switch (type) {
    case types.FETCH_CLOUDGAMES_APPS:
      state.cloudGames = payload
      break
    default: {
    }
  }
}, INIT_STATE)
