import produce from 'immer'
import * as types from './constant'

const INIT_STATE = {
  allPlayerListPri: [],
  allPlayerListPub: [],
  allPlayerListUse: [],
  firstPlayer: [],
  playerlist: [],
  page: 1,
  geo: '',
  limit: 120,
  lastPage: 1,
  appInfoCache: {},
  appInfoCaching: {},
  refreshTime: null,
  uploadLikeDisLikeTime: {},
  fetchPlayListClickTime: null,
  appInfoUpdataIndex: 0,
}
const playerlistReducer = produce((state, action) => {
  const { type, payload } = action
  switch (type) {
    case types.SET_PLAY_LIST: {
      state.playerlist = payload
      break
    }
    case types.SET_GE0_COUNTRY: {
      state.geo = payload
      break
    }
    case types.ADD_APP_INFO_CACHE: {
      state.appInfoCache[payload['package_name']] = payload
      let playerList = state.playerlist
      for (let player of playerList) {
        let games = player.games
        for (let targetgame of games) {
          if (targetgame.package_name === payload.package_name) {
            targetgame['icon_url'] = payload['icon_url']
            targetgame['game_name'] = payload['game_name']
            targetgame['action'] = payload['action']
            targetgame['apk_url'] = payload['apk_url']
            targetgame['game_play_preference'] = payload['game_play_preference']
            targetgame['section'] = payload['section']
          }
        }
      }
      break
    }
    case types.UPDATE_PLAY_LIST_LIKE: {
      let playerList = state.playerlist
      // 只更新当前player下的app
      // const itemIndex = playerList.findIndex((player) => player.email === payload.email)
      // const gameIndex = playerList[itemIndex].games.findIndex(
      //   (item) => item.package_name === payload.package_name
      // )
      // const targetgame = playerList[itemIndex]['games'][gameIndex]
      // if (payload.islike) {
      //   targetgame.likes++
      // } else {
      //   targetgame.likes--
      // }
      // targetgame.isLike = payload.islike
      // 更新全部同名game，后台以一个用户和一个app来做统计。不是不同的player
      for (let player of playerList) {
        let games = player.games
        for (let targetgame of games) {
          if (targetgame.package_name === payload.package_name) {
            if (payload.islike) {
              targetgame.likes++
            } else {
              targetgame.likes--
            }
            targetgame.isLike = payload.islike
          }
        }
      }

      break
    }
    case types.CHANGE_APP_INFO_CLOUD_INFO: {
      const { playIndex, gameIndex, cloud_supported, cloud_app_url } = payload
      state.playerlist[playIndex].games[gameIndex].cloud_supported = cloud_supported
      state.playerlist[playIndex].games[gameIndex].cloud_app_url = cloud_app_url
      break
    }
    case types.UPDATE_PAGE_INFO: {
      state.page = payload.page
      state.lastPage = payload.lastPage
      break
    }
    case types.SET_TIME_REFRESH: {
      state.refreshTime = payload
      break
    }
    case types.ADD_APP_INFO_CACHING: {
      state.appInfoCaching[payload] = true
      break
    }
    case types.DEL_APP_INFO_CACHING: {
      delete state.appInfoCaching[payload]
      break
    }
    case types.SET_FIRST_PLAY_LIST: {
      state.firstPlayer = payload
      break
    }
    case types.SET_LIKE_TIMEOUT_ID: {
      const { itemId, timeoutId } = payload
      state.uploadLikeDisLikeTime[itemId] = timeoutId
      break
    }
    case types.DEL_LIKE_TIMEOUT_ID: {
      const { itemId } = payload
      delete state.uploadLikeDisLikeTime[itemId]
      break
    }
    case types.SET_FETCH_CLICK_TIMEOUT_ID: {
      state.fetchPlayListClickTime = payload
      break
    }
    case types.DEL_FETCH_CLICK_TIMEOUT_ID: {
      state.fetchPlayListClickTime = null
      break
    }
    case types.SET_ALL_PLAY_LIST_PUB: {
      state.allPlayerListPub = payload
      break
    }
    case types.SET_ALL_PLAY_LIST_PRI: {
      state.allPlayerListPri = payload
      break
    }
    case types.SET_ALL_PLAY_LIST: {
      state.allPlayerListUse = payload
      break
    }
    case types.CLEAN_ALL_PLAY_LIST: {
      state.allPlayerListUse = []
      state.playerlist = []
      break
    }
    case types.SET_APP_INFO_UPDATE_INDEX: {
      state.appInfoUpdataIndex = payload
      break
    }
    default: {
    }
  }
}, INIT_STATE)

export default playerlistReducer
