import * as types from './constant'
import { appDetailDataTransformer, getSpecialSizeIconByReplace } from '@/transformers'

// Set app detail action(data adaptation)
export const setAppDetail = ({ key, value = {} }) => {
  const _appInfo = {
    ...value,
    game_overview: {
      icon_url: getSpecialSizeIconByReplace(value.game_overview?.app_icon),
      ...(value.game_overview || {}),
    },
    similar_apps: appDetailDataTransformer(value.similar_apps || []),
    similar_category_games: appDetailDataTransformer(value.similar_category_games || []),
  }
  return {
    type: types.SET_APP_DETAIL,
    payload: { key, value: _appInfo },
  }
}

// Push app detail stack
export const pushAppStack = (app) => {
  return {
    type: types.PUSH_APP_STACK,
    payload: app,
  }
}
// Pop app detail stack
export const popAppStack = (app) => {
  return {
    type: types.POP_APP_STACK,
  }
}
