/**
 * Default host config(from env file)
 */

const hostMap = {
  baseHost: process.env['REACT_APP_BASE_HOST'],
  cloudHost: process.env['REACT_APP_CLOUD_HOST'],
  xHost: process.env['REACT_APP_XHOST'],
  ssoHost: process.env['REACT_APP_SSO_HOST'],
  detailHost: process.env['REACT_APP_DETAIL_HOST'],
}

export default hostMap
