import React, { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import GameCarouselSection from '@/components/CarouselSection/GameCarouselSection'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import GameGridLayout from '@/components/layout/gameGridLayout/Index'
import Banner from './Banner_v2'
import SkeletonGameList from '@/skeletons/GameList'
import { qwebApi } from '@/apis/qwebApi'
import { useGridBaseCount } from '@/hook/useViewport'

// import { arrayShuffle } from '@/tool'

import {
  STAT_HOME_PAGE_TRENDINGGAMES,
  STAT_HOME_PAGE_POPULARGAMES,
  STAT_HOME_PAGE_JUNGAMES,
} from '@/tool/constant'
import { useEffect } from 'react'
import classNames from 'classnames'
// import { useMemo } from 'react'

const List = () => {
  const containerRef = useRef(null)
  const countryCode = useSelector((state) => state.system.countryCode)
  const loadingStore = useSelector((state) => state.loading)
  // 云游戏
  const cloudApps = useSelector((state) => state.cloud.cloudGames) || []
  // 置顶游戏（selected_game）
  const topApps = useSelector((state) => state.app.topApps)
  // // 最新游戏（latest_game）
  // const recommendedApps = useSelector((state) => state.app.recommendedApps) || []

  // 火热游戏（hot_list）
  const hotApps = useSelector((state) => state.app.hotApps)

  // const shuffleTopApps = useMemo(() => {
  //   return arrayShuffle([...topApps])
  // }, [topApps])

  // const shuffleHotApps = useMemo(() => {
  //   return arrayShuffle([...hotApps])
  // }, [hotApps])

  const gridCount = useGridBaseCount()

  // 滚动事件
  const _onContainerScroll = useCallback((e) => {
    if (e.target) {
      qwebApi({
        event: 'onPageScroll',
        data: {
          x: window.getRealMeasurement(e.target.scrollLeft),
          y: window.getRealMeasurement(e.target.scrollTop),
        },
      })
    }
  }, [])

  useEffect(() => {
    const scrollContainer = containerRef.current?.parentElement
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', _onContainerScroll, false)
    }
    return () =>
      scrollContainer && scrollContainer.removeEventListener('scroll', _onContainerScroll)
  }, [_onContainerScroll])
  return (
    <div className={classNames(['homeList', 'breakScreen'])} ref={containerRef}>
      <Banner className="homeListBanner" gridCount={gridCount} />
      {/* cloud */}
      {countryCode === 'JP' &&
        (cloudApps.length ? (
          <ErrorFallback>
            <GameCarouselSection
              carouselKeyId="homeCloudGames"
              titleClass="spaceBetween"
              title="加藤純一アプリ王選手権作品"
              linkTo={{
                pathname: 'apps',
                search: 'type=cloudGames&from=home&title=加藤純一アプリ王選手権作品',
              }}
              list={cloudApps}
              imgPlaceholderClass="ratio-1-1"
              bannerLocation={STAT_HOME_PAGE_JUNGAMES}
              showCloudBtn
              showPlayBtn
              slidesPerView={gridCount}
              isGroup={false}
            />
          </ErrorFallback>
        ) : (
          <SkeletonGameList
            imgPlaceholderClass="ratio-1-1"
            title="加藤純一アプリ王選手権作品"
            column={gridCount}
            enable={loadingStore.cloudGameLoading}
          />
        ))}
      {/* hot-Popular Games in your region */}
      {hotApps.length ? (
        <ErrorFallback>
          <GameCarouselSection
            carouselKeyId="homeHotGames"
            titleClass="spaceBetween"
            titleId="androidGamesLike"
            list={hotApps}
            // className="margin-bottom-30"
            linkTo={{
              pathname: 'apps',
              search: 'type=hotApps&titleId=androidGamesLike&from=home',
            }}
            showPlayBtn
            showCloudBtn
            bannerLocation={STAT_HOME_PAGE_POPULARGAMES}
            imgPlaceholderClass="ratio-3-4"
            imageUrlGet={(game) => (countryCode === 'JP' ? game.icon_url : game.banner_url_v)}
            showInstalledState
            slidesPerView={gridCount - 1}
            isGroup={false}
          />
        </ErrorFallback>
      ) : (
        <SkeletonGameList
          imgPlaceholderClass="ratio-3-4"
          titleId="androidGamesLike"
          column={gridCount - 1}
          enable={loadingStore.gameCenterHomeLoading}
        />
      )}
      {/* top-Trending Games */}
      {topApps.length ? (
        <ErrorFallback>
          <GameCarouselSection
            carouselKeyId="homeTopGames"
            titleClass="spaceBetween"
            titleId="cloudGamesLike"
            linkTo={{
              pathname: 'apps',
              search: 'type=topApps&from=home&titleId=cloudGamesLike',
            }}
            list={topApps}
            showInstalledState
            imgPlaceholderClass="ratio-1-1"
            bannerLocation={STAT_HOME_PAGE_TRENDINGGAMES}
            showCloudBtn
            showPlayBtn
            slidesPerView={1}
            groupNumber={gridCount * 2}
            renderItem={({ slide, itemProps }) => {
              return (
                <GameGridLayout
                  contentInnerClass="margin-bottom-30"
                  gameItemProps={itemProps}
                  list={slide}
                  columns={gridCount}
                />
              )
            }}
          />
        </ErrorFallback>
      ) : (
        <SkeletonGameList
          imgPlaceholderClass="ratio-1-1"
          titleId="cloudGamesLike"
          column={gridCount}
          row={2}
          enable={loadingStore.gameCenterHomeLoading}
        />
      )}
    </div>
  )
}

export default React.memo(List)
