// set installed apps type
export const SET_INSTALLED_APPS = 'SET_INSTALLED_APPS'

// set installed pc games type
export const SET_INSTALLED_PC_GAMES = 'SET_INSTALLED_PC_GAMES'

// set rotation apps type
export const FETCH_ROTATION_APPS = 'FETCH_ROTATION_APPS'

// set game center home apps type
export const FETCH_GAME_CENTER_HOME_APPS = 'FETCH_GAME_CENTER_HOME_APPS'

// shuffle recommended apps type
export const SHUFFLE_RECOMMENDED_APPS = 'SHUFFLE_RECOMMENDED_APPS'

// set sidepanel apps type
export const FETCH_SIDEPANEL_APPS = 'FETCH_SIDEPANEL_APPS'

// set feature apps type
export const FETCH_FEATURED_APPS = 'FETCH_FEATURED_APPS'

// set topic apps type
export const SET_TOPIC_APPS = 'SET_TOPIC_APPS'

// local perference type
export const SET_LOCAL_PERFERENCE = 'SET_LOCAL_PERFERENCE'

// gp-search suggested apps
export const FETCH_SUGGESTED_APPS = 'FETCH_SUGGESTED_APPS'
